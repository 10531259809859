@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

@mixin center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center
}

.home-container {
    @include theme-aware('width', 'main-home-banner-widht');
    
    #a { grid-area: a; }
    #b { grid-area: b; }
    
    padding: 0;
    margin-bottom: 235px;
    width: unset;
    
    //Responsive Stuff
    @media (max-width: $mobile) {
        width: auto;
        #grid {
            @include theme-aware('display', 'home-mobile-display');
            display: grid;
            grid-template-areas:
            "b" 
            "a";
        }
    }
    
    .columns {
        @media (max-width: $mobile) {
            margin: 20px 0 0 0 !important;
        }
        .column, .is-triumph-section {
            @media (max-width: $mobile) {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }

    >.home-bottom-content {
        // justify-content: flex-start;
        overflow-y: hidden !important;

        @media (max-width: $mobile) {
            // justify-content: center;
            width: 100% !important;
        }

        .news-parent-container {    
            @media (max-width: $mobile) {
                width: 100% !important;
            }
        }
    }

    >.is-content-section {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        
        .ScrollbarsCustom-Scroller {
            @media (max-width: $mobile) {
                margin-right: -25px;
                padding-right: 0 !important;
            }
        }
        .is-title-section {
            @include center;
            color: $brown;
        }
        
        .is-triumph-section {
            @media (max-width: $mobile) {
                margin: 0 !important;
            }
            .is-triumph-list {
                @include center;
                justify-content: center;
                margin-bottom: 50px;
            }
            
        }
    }
}

