@import '../../shared/sass/variables.scss';

.video-modal {
    .close-button {
        cursor: pointer;
        font-size: 25px;
        top: 0;
        position: absolute;
        right: 5px;
    }
    .modal-title {
        border-bottom: 1px solid $brown;
        margin-bottom: 15px; 
        .video-modal-text {
            color: $brown;
            font-size: 20px !important;
            line-height: 28px;
            letter-spacing: 4px;
            text-align: center;
        }
    }
    .attachment-area {
        align-items: center;
        border-radius: 5px;
        border: dashed 2px grey;
        color: gray;
        cursor: pointer;
        display: flex;
        font-size: 35px;
        justify-content: center;
        height: 45px;
        width: 40%;
    }
    .ReactModal__Overlay--after-open { 
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            align-items: center;
            background-color: $gray-lighter !important;
            border-color: $gray-darkness !important;
            border-radius: 15px !important; 
            display: flex;
            flex-direction: column;
            justify-content: center;
            bottom: 10% !important;
            top: 10% !important;
            left: 31% !important;
            right: 31% !important;
            overflow: hidden !important;  
            .content-text {
                line-height: 18px;
                text-align: center;
            }      
            @media (max-width: $mobile) {
                bottom: 10% !important;
                right: 10% !important;
                left: 10% !important;
                top: 10% !important;
                .modal-title {
                    .video-modal-text {
                        font-size: 20px !important;
                    }
                }
                .content-text {
                    font-size: 15px !important;
                    text-align: center;
                }
            }
        }
    }
}