// Light Theme Palette Colors
$red: #b73f39;
$black: #161616;
$brown: #c19656;
$gray-pause: #a9a9a9;
$gray-lighter: #f2f2f2;
$gray-darkness: #212121;
$green-petroleum: #2f5f55;
$dark-gray: #5e5e5e;
$gray-progress: #3a3a3a;
$gray-tool: #dedede;
$support-background: #dbdbdb !important;

// Others
$info: #293e53;
$form-border: #909090da;
$support-input: #d6d6d6d5;
$green-toggle: #00a546;
$green-toggle-disabled: #00a54678;

//Elite MasterMind Palette Colors
$dark-blue: #1b2026;
$gray-dark-blue: #2c3137;
$gray-light-blue: #222c39;
$blue: #29476b !important;
$gray-lighter: #dbdbdb;
$gray-lighter-secondary: #808080;

//Dark Mode Variables
$support-card-background: $brown !important;
$background-support-dark: $gray-darkness !important;
$color-nav-buttons: white;
$dark-background: $gray-dark-blue;
$dark-container: $gray-darkness;
$gray-light-blue: #222c39;
$blue: #29476b !important;
$gray-lighter: #dbdbdb;
$gray-lighter-secondary: #808080;

//Extra Themes Colors
$rating-color: white !important;

//Themes Settings Prefs
$arrow-icon-elite: url("https://cdn.bemaster.com/media/icons/home/Sitiobemaster34-White.svg");
$display-type: none !important;
$Fondo-Pantalla: "https://cdn.bemaster.com/media/images/home/Fondo-Pantalla.png" !important;
$front-layer-visible: transparent !important;
$home-banner-background: url("https://cdn.bemaster.com/media/images/home/Banner-Principal-Elite.png") !important;
$logo-width: 75% !important;
$logo-elite-width: 75% !important;
$logo-margin-top: 20px;
$logo-margin-bottom: 15px;
$logo-home-margin-bottom: 20px !important;
$logo-home-margin-top: 15px !important;
$mobile-classroom-navbar: center !important;
$support-navbar-margin-top: 0 !important;
$support-navbar-width: 100% !important;
$banner-home-mobile-background: url("https://cdn.bemaster.com/media/images/home/Home-sm2-Elite.png");
$brand-icon-elite: url("https://cdn.bemaster.com/media/images/login/Logo-BeMaster-Elite.png");

//Bemaster Primer
$bemaster-prime-main-background: url("https://cdn.bemaster.com/media/images/powerclasses/bemaster-prime-background.png") !important;


//Themes Maps Hell Yeah!
$themes: (
    light: (
        color-background: $gray-lighter,
        color-card: #fff,
        color-class-info: $brown,
        color-class-description: $dark-gray,
        color-missions-title: $brown,
        color-rating: $brown,
        logo-width: $logo-width,
        news-title-color-background: $brown,
        unchecked-border-color: $brown,
    ),
    dark: (
        background-support-dark: $background-support-dark,
        color-background: $dark-background,
        color-background-container: $dark-container,
        color-card: #212121,
        color-text: white,
        color-class-info: $gray-lighter,
        color-class-description: $gray-lighter,
        color-missions-title: white,
        color-mission-title-background: $gray-lighter-secondary,
        color-mission-item: $blue,
        color-checked-mission: $blue,
        color-rating: $rating-color,
        logo-width: $logo-width,
        unchecked-border-color: $blue,
        news-title-color-background: $gray-lighter-secondary,
        prev-next-buttons: $color-nav-buttons,
        support-navbar-background: $gray-dark-blue,
        support-card-background: $support-card-background,
    ),
    emm: (
        arrow-icon-elite: $arrow-icon-elite,
        account-link-visible: none,
        background-home: $Fondo-Pantalla,
        banner-background: $home-banner-background,
        banner-info-section: none,
        banner-home-mobile-background: $banner-home-mobile-background,
        brand-icon-elite: $brand-icon-elite,
        color-background: $gray-dark-blue,
        color-card: #212121,
        color-text: $gray-lighter,
        color-class-info: $gray-lighter,
        color-class-description: $gray-lighter,
        color-mission-item: $blue,
        color-mission-title-background: $gray-lighter-secondary,
        color-missions-title: white,
        color-checked-mission: $blue,
        color-rating: $rating-color,
        clasificado-community-color: white,
        class-title-margin-top: 15px,
        classroom-mobile-navbar-height: unset,
        elite-logo-width: 40%,
        front-layer-visible: $front-layer-visible,
        hide-progress-bar-pensum: none,
        home-mobile-display: block,
        home-navbar-items-order: space-evenly,
        logo-width: $logo-elite-width,
        logo-margin-bottom: $logo-margin-bottom,
        logo-margin-top: $logo-margin-top,
        logo-home-margin-bottom: $logo-home-margin-bottom,
        logo-home-margin-top: $logo-home-margin-top,
        unchecked-border-color: $blue,
        main-home-banner-height: 320px,
        main-home-banner-widht: 95%,
        mission-bottom-section: #f4f4f4,
        mobile-classroom-navbar: $mobile-classroom-navbar,
        news-title-color-background: $gray-lighter-secondary,
        prev-next-buttons: white,
        prev_next_button: $blue,
        reward-title: transparent,
        reward-title-visible: none,
        support-navbar-background: $gray-dark-blue,
        support-navbar-margin-top: $support-navbar-margin-top,
        support-navbar-width: $support-navbar-width,
        triumph-list-visible: none,
        workbook-display: $display-type,
    ),
    prime: (
        background-bemaster-primer: $bemaster-prime-main-background,
        color-background: $gray-lighter,
        color-card: #fff,
        color-class-info: $brown,
        color-class-description: $dark-gray,
        color-missions-title: $brown,
        color-rating: $brown,
        logo-width: $logo-width,
        news-title-color-background: $brown,
        unchecked-border-color: $brown,
    )
);

// Responsive Dimentions
$mobile: 786px;
$desktop: 750px;
$big-screen: 1000px;
$breakpoint: 900px;
$breakpoint-rating: 860px;

//Some custom variables
$main-window-background: #212121;
$conf-window-background: #f3f3f3;

// Fires
$fire-records-height: 40px;
$fire-records-width: 80%;
// $fire-records-width: 600px;

// Icon Variables
$icon-width: 30px;
$icon-heigth: 25px;

$width-navbar-icon: 20px;

// Triumph Variables
$triumph-width: 65px;
$triumph-heigth: 65px;

// Reward Icon
$icon-reward-heigth: 40px;
$icon-reward-width: 40px;

// Junior Color
$junior: $red;

// Senior Color
$senior: $green-petroleum;

// Master Color
$master: $brown;

// Classroom Variables
$work-section-height: 75%;

// Update Bulma's global variables
$is-danger: $junior;
$family-sans-serif: "Gotham", sans-serif;
$grey-dark: $gray-darkness;
$gray-ligh: $gray-lighter;
$primary: $red;
$link: $black;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $grey-dark;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
