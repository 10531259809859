@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.main-fires-container {
    width: 100%;
    margin-bottom: 200px;
    .right-top-button {
        button {
            @media (max-width: $mobile) {
                border-radius: 10px;
                margin-top: 5% !important;
            }
        }
    }
    .top-fires-container {
        align-items: center;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(19,19,19,1) 60%, rgba(57,57,57,1) 100%);
        border-top-left-radius: 31px;
        border-top-right-radius: 31px;
        color: white;
        display: flex;
        height: 140px;
        justify-content: space-between;
        padding: 0 45px;
        @media (max-width: $mobile) {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            display: flex;
            flex-direction: column;
        }
        .fires-icon {
            img {
                // height: 75px;
                display: flex;
                margin-left: -2px;
                @media (max-width: $mobile) {
                    // height: 60px;
                }
            }
            width: 23% !important;
        }
        .titles-top-fires {
            @media (max-width: $mobile) {
                line-height: 15px;
            }
            .balance {
                margin: 0 !important;
                h1 {
                    @media (max-width: $mobile) {
                        font-size: 13px !important;
                    }
                }
            }
            .fires-top-title {
                h1 {
                    @media (max-width: $mobile) {
                        font-size: 17px !important;
                    }
                }
            }
        }
        .left-top-elements {
            align-items: center;
            display: flex;
            @media (max-width: $mobile) {
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 15px !important;
            }
        }
        .right-top-button {
            display: flex;
            height: 70%;
            .right-top-button {
                button {
                    @media (max-width: $mobile) {
                        margin-top: 5% !important;
                    }
                }
            }
        }
    }

    .movements-section {
        display: flex;
        height: 80vh;
        justify-content: center;
        margin-bottom: 200px !important;
        @media (max-width: $mobile) {
            padding: 0% !important;
        }
        .has-bottom-movements {
            border-top: 1px solid $form-border;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 20px;
            width: 95%;
            @media (max-width: $mobile) {
                padding: 10px !important;
            }
            .title-top-section {
                @include theme-aware('color', 'color-text');
                align-items: center;
                color: $brown;
            }
            .movements-block {
                height: 100%;
                .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
                    background-color: transparent !important;
                }
                .ScrollbarsCustom-TrackY {
                    height: calc(100% - 60px) !important;
                    top: 40px !important;
                }
                .ScrollbarsCustom-Content {
                    padding-bottom: 260px !important;
                }
            }
        }
    }

}