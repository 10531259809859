@import '../../shared/sass/variables.scss';

.community-message-container {
    background-color: white;
    border-radius: 25px;
    display: flex;
    height: 79%;
    justify-content: center;
    min-width: 930px;
    margin-left: 24px;
    padding: 90px 10px;
    @media (max-width: $mobile) {
        height: 40%;
        margin: auto;
        padding: 20px 10px;
        min-width: unset !important;
        width: 95% !important;
    }
    >.main-community-modal-container {
        align-items: center;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        font-weight: 100;
        justify-content: center;
        height: 230px;
        text-align: justify;
        width: 75%;
        @media (max-width: $mobile) {
            width: 90%;
            h1 {
                line-height: 20px !important;
                font-size: 16px !important;
            }
            button {
                width: auto !important;
            }
        }
        h1 {
            font-size: 25px;
            font-weight: 100;
            line-height: 30px;
            .text-chunk-2 {
                font-weight: bold;
            }
        }
    }
}