@import "../../../shared/sass/essentials.styles.scss";
@import "../../../shared/sass/variables.scss";

.main-navbar-mobile {
    align-items: center;
    background-color: rgb(0, 0, 0);
    bottom: 0;
    display: flex;
    height: 80px;
    justify-content: space-evenly;
    position: fixed;
    width: 100%;
    z-index: 9;

    @media (min-width: $mobile) {
        display: none;
    }

    .bottom-nav-icon {
        align-items: center;
        display: flex;
        height: 40%;

        img {
            height: 27px;
        }
    }
}