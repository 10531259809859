@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.basic-info-container {

    >.has-loader {
        display: grid;
        height: 50vh;
        place-items: center;

        img {
            width: 8%;
        }
    }

    .ScrollbarsCustom-Content {
        // padding-bottom: 200px !important;
    }
    .ScrollbarsCustom, .trackYVisible {
        .ScrollbarsCustom-TrackY {
            height: calc(100% - 140px) !important;
            top: 80px !important;
            background-color: transparent !important;
            .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
                background-color: transparent !important;
            } 
        }
        .ScrollbarsCustom-Scroller {
            padding-left: 5px !important;
        }
    }
    .custom-left-m {
        margin-left: 2px;
    }
    .custom-left-m {
        margin-left: 1px;
    }
    h1 {
        @include theme-aware('color', 'color-text');
        color: unset;
        font-weight: bold;
    }
    .top-form-section {
        // border-bottom: solid 1px $form-border;
        width: 100%;
        @media (max-width: $mobile) {
            padding-bottom: 20px !important;
            width: 88%;
        }
        .columns {
            flex-wrap: wrap;
            // @media (min-width: 1290px) {
            //     display: grid ;
            //     grid-template-columns: 1fr 1fr;
            // }

            .column {
                flex-shrink: 3;
                
                .genre-section {
                    width: 250px !important;
                    @media (max-width: $mobile) {
                        width: 100% !important;
                    }
                }
                .email-section{
                    .e-input {
                        @media (max-width: $mobile) {
                            width: 100% !important;
                        }
                    }
                }
                .country {
                    width: 250px !important;
                }
                .e-input {
                    width: 250px !important;
                    .is-form-input {
                        @media (max-width: $mobile) {
                            width: 100% !important;
                        }
                    }
                }
            }


        }
        .c-input {
            display: flex;
            align-items: center;
            .flag-select {
                .flag-select__btn {
                    background: none;
                    border: none;
                    .flag-select__option {
                        img {
                            height: 25px;
                            max-width: 40px;
                        }
                    }
                }
            }
            .main-dropdown-container {
                width: 250px;
                @media (max-width: $mobile) {
                    width: 100% !important;
                }
            }
        }
        .state-section {
            .e-input {
                @media (max-width: $mobile) {
                    width: 100% !important;
                }
            }
        }
        .city-section {
            .ct-input {
                .is-form-input {
                    @media (max-width: $mobile) {
                        width: 100% !important;
                    }
                }
            }
        }
        .ct-input, .e-input {
            .is-form-input {
                width: 250px !important;
            }
            @media (max-width: $mobile) {
                width: 100% !important;
            }
        }
        .p-input input, .a-input input,
        .e-input input, .ct-input input {
            @media (max-width: $mobile) {
                width: 100% !important;
            }
        }
    }
    .bottom-form-section {
        width: 100%;
        .change-password-title{
            margin-bottom: 30px;
        }
        @media (max-width: $mobile) {
            width: 88%;
            margin-bottom: 100px !important;
        }
        .forgot-password {
            font-size: 15px;
            color: $form-border;
            cursor: pointer;
        }
        .confirm-pass-container {
            padding-left: 0 !important;
            @media (max-width: $mobile) {
                padding: 0 !important;
                margin-bottom: 50px;
            }
        }
        .ct-input input {
            @media (max-width: $mobile) {
                width: 100% !important;
            }
        }
    }
}