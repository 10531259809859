@import '../../shared/sass/variables.scss';
@import '../card/card.styles.scss';

.main-certification-video-container {
    background-color: $grey-dark;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 100%;
    width: 80%;
    @media (max-width: $mobile) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow-y: auto;
        width: 100%;
    }

    .typeform-share  {
        display:inline-block;
        text-decoration:none;
        background-color:#3A7685;
        color:white;cursor:pointer;
        font-family:Helvetica,Arial,sans-serif;
        font-size:20px;
        line-height:50px;
        text-align:center;
        margin:0;
        height:50px;
        padding:0px 33px;
        border-radius:17px;
        max-width:100%;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        font-weight:bold;
        -webkit-font-smoothing:antialiased;
        -moz-osx-font-smoothing:grayscale;
    }

    .mega-title {
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background: rgb(193,150,86);
        background: linear-gradient(90deg, rgba(193,150,86,1) 0%, rgba(175,140,88,1) 76%, rgba(224,199,161,1) 100%);
        background-position: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        height: 20%;
        justify-content: center;
        
        @media (max-width: $mobile) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 20px 15px;
        }
        
        h1 {
            color: white;
            font-size: 20px !important;
            line-height: 25px;
            letter-spacing: 5px;
            width: 100%;
        }
    }
    .bottom-video-certification-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 30px;
        .react-player__preview {
            @include boxShadow(0, 0, 30px, 0px, 2);
        }
        .video-player {
            width: 45%;
            @media (max-width: $mobile) {
                width: 106%;
            }
            .video-react .video-react-progress-holder .video-react-play-progress {
                background-color: $red !important;
            }
            .video-react-control-bar, .video-react-control-bar-auto-hide {
                background-color: #00000066 !important;
            }
            .video-react .video-react-play-progress:before {
                color: $red !important;
            }
            .video-react .video-react-big-play-button {
                background-color: $red !important;
            }
        }

        .celebration-text {
            color: $support-input !important;
            font-size: 16px !important;
            letter-spacing: 3px;
            line-height: 20px;
            text-align: center;
            @media (max-width: $mobile) {
                font-size: 15px !important;
            }
        }
        .last-text {
            color: $support-input !important;
            font-size: 15px !important;
            letter-spacing: 3px;
        }
        .button {
            margin: 0 !important;
        }
        @media (max-width: $mobile) {
            padding: 20px !important;
        }
    }
}