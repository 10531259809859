@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.main-card-tool {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #a5a5a59c;
    cursor: pointer;
    display: flex;
    height: 350px;
    flex-direction: column;
    transition: all ease-in-out 0.32s;

    &:hover {
        transition: all ease-in-out 0.32s;
        -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
        -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
    }
    .top-card-section {
        align-items: center;
        display: flex;
        background-position: center;
        background-size: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        height: 50%;
        padding: 15px;

        >h1 {
            color: white;
            font-weight: 800;
            font-size: 22px !important;
            line-height: 22px;
        }
    }

    .bottom-card-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 15px;

        p { 
            text-align: justify;
            margin: 0 0 10px 0;
        }
    }
}
