@import "../../../shared/sass/variables.scss";

.main-subscription-container {
    background-color: $gray-darkness;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 1.5rem;
    width: 55%;

    @media (max-width: $mobile) {
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 1rem;
        width: 100%;
    }
    
    .left-side-container {
        display: flex;
        flex-direction: column;
        line-height: 11px;

        > .icon-area {
            img {
                filter: invert(1);
                width: 150px;
            }
        }

        > .price-area {
            span {
                color: white !important;
                font-size: 9px;
            }
        }
    }

    .cancelation-form {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
    }
}