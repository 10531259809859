@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.community-container {
    height: 100%;
    .columns {
        width: 100%;
        height: 100%;

        @media (max-width: $mobile) {
            #c {
                grid-area: c;
            }
            #d {
                grid-area: d;
            }

            #grid {
                display: grid;
                grid-template-areas:
                    "c"
                    "d";
            }
        }
    }
    @media (min-width: $mobile) {
        margin: 0 !important;
        width: 100%;
    }
    .com-content-section {
        border-radius: 25px;
        background-color: $grey-dark;
        display: flex;
        height: 100% !important;
        margin-left: 85px;
        padding: 20px;
        width: 100%;
        @media (max-width: $mobile) {
            justify-content: center;
            margin-left: 0 !important;
            margin-top: 5%;
            width: 85%;
        }
        .events-section {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0 !important;
            padding: 10px;
            width: 100%;
            @media (max-width: $mobile) {
                min-height: -webkit-fill-available;
                width: 90%;
                .ScrollbarsCustom-Content {
                    padding-left: 0 !important;
                }
            }
            .ScrollbarsCustom-TrackY {
                height: calc(100% - 255px) !important;
                top: 115px !important;
            }
            .ScrollbarsCustom-Thumb {
                height: 100px !important;
                padding-right: 15px;
            }
            > .is-events-title {
                align-items: center;
                display: flex;
                justify-content: center;
                @media (max-width: $mobile) {
                    h2 {
                        font-size: 15px !important;
                    }
                }
                > .b-title {
                    letter-spacing: 5px;
                }
            }
            .has-card-list-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;
                padding-bottom: 15px;
                width: 95%;
            }
            > .news-area {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                > .news-title {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    .classified-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 0;

        .classified-card {
            align-items: center;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 15px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 375px;
            justify-content: center;
            margin: auto;
            transition: all ease-in-out 0.35s;
            width: 60%;
            
            @media (max-width: $mobile) {
                background-size: cover;
                height: 330px !important;
                width: 100%;
            }

            > .resume-classified {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                h1 {
                    width: 160px;
                }
            }
        }

        .slider {
            width: 80%;
            height: 400px;

            @media (max-width: $mobile) {
                margin: 10rem 0;
                position: absolute;
                width: 60% !important;
            }

            .classified-card {
                align-items: center;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: white;
                border: solid;
                border-radius: 15px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                height: 400px;
                justify-content: center;
                margin: auto;
                transition: all ease-in-out 0.35s;
                width: 80%;
                object-fit: cover;
                > .resume-classified {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    h1 {
                        width: 160px;
                    }
                }
            }
        }

        .button-area {
            cursor: pointer;
            width: 60%;
            @media (max-width: $mobile) {
                display: flex !important;
                justify-content: center;
                margin-top: 15px;
            }
            > .custom-button-telegram {
                align-items: center;
                display: flex;
                background-color: rgba(0, 0, 0, 0.705);
                border-radius: 12px;
                border-color: rgba(255, 255, 255, 0.199) !important;
                justify-content: center;
                min-height: 80px;
                transition: all ease-in-out 0.32s;
                width: 100%;
                
                &:hover {
                    border-color: white !important;
                    cursor: pointer;
                }

                @media (max-width: $mobile) {
                    width: auto;
                }

                h1 {
                    color: white !important;
                }

                > .telegram-icon {
                    svg {
                        color: white;
                        height: 18px;
                        width: 18px;
                    }
                    align-items: center;
                    background-color: #0088CC;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    width: 35px;
                }
            }
            > .button {
                background-color: $gray-lighter;
                border-radius: 12px;
                border-color: transparent !important; 
                min-height: 80px;
                width: 100%;
                @media (max-width: $mobile) {
                    width: auto;
                }

                h1 {
                    color: $brown;
                }
                > .face-icon {
                    svg {
                        color: #3B5998;
                        height: 18px;
                        width: 18px;
                    }
                    align-items: center;
                    background-color: transparent;
                    border-radius: 50%;
                    border: 1px solid #3B5998;
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    width: 35px;
                }
            }
        }
        .classified {
            @include theme-aware("color", "clasificado-community-color");
            color: $brown;
        }
    }
}

.community-mobile-container {
    .ScrollbarsCustom-Track,
    .ScrollbarsCustom-Thumb {
        background-color: transparent !important;
    }
}

.mobile-content {
    padding-bottom: 190px !important;
}

.classified-card:hover {
    @include boxShadow(3px, 3px, 15px, 0px, 0.45);
    border: 2px solid $brown;
    transform: scale(1.02);
}
