@import '../../shared/sass/variables.scss';

.main-product-container {
    align-items: center;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
    .product-container {
        display: flex;
        width: 340px;
        height: 190px;
        // @media (max-width: $mobile) {
        //     grid-gap: 45px !important;
        // }
        .p-card {        
            border-radius: 10px;
            height: 100%;
            width: 50%;
            @media (max-width: $mobile) {
                width: 45% !important;
                height: 100% !important;
            }
        }
        .details-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding-left: 15px;
            width: 50%;
            @media (max-width: $mobile) {
                width: 50%;
            }
            .mini-price {
                align-items: center;
                display: flex;
            }
            h1 {
                color: white;
                line-height: 20px;
            }
        }
    }
}

// CDN Simulation
.product02 {
    background-image: url('https://cdn.bemaster.com/media/images/shopping/products/product1.PNG');
    background-position: center center;
    background-size: cover;
}
.product03 {
    background-image: url('https://cdn.bemaster.com/media/images/shopping/products/product2.PNG');
    background-position: center center;
    background-size: cover;
}
.product04 {
    background-image: url('https://cdn.bemaster.com/media/images/shopping/products/product3.PNG');
    background-position: center center;
    background-size: cover;
}