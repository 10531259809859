@import "../../shared/sass/variables.scss";

.reward-anim {
    display: grid;
    place-items: center;

    img {
        border-radius: 2rem;
        height: 140px;
        width: 140px;
    }
}
