@import '../../../../shared/sass/variables.scss';

.is-noti-card {
    align-items: center;
    border-radius: 7px;
    background-color: #d4d4d4;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    min-height: 80px;
    margin: 10px 0;
    padding: .3rem;
    width: 95%;

    &:hover {
        background-color: #bebebe;
    }
    >.notification-resume {
        line-height: 15px;
        width: 83%;
    }
    .buttons-section {
        display: flex;

        @media (max-width: $mobile) {
            right: 13% !important;
        }
        .close-icon { 
            cursor: pointer;
            right: 0;
            font-size: 15px !important;
        }
        .notification-state {
            color: $red;
            cursor: pointer;
        }
    }

    .is-noti-text{
        font-weight: bold;
        font-size: 12px;
        flex: 1;
        letter-spacing: 0.5px;
        padding: 5px;
        // width: 90% !important;
        word-wrap: break-word;
    }
}