@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

@mixin center {
    align-items: center;
    display: flex;
}

$c-breakpoint: 1000px;
.loading-layout {
    background-color: #f4f4f47a;
    border-radius: 25px;
    display: grid;
    height: 100%;
    place-items: center;
    position: inherit;
    width: 290px;
    z-index: 9999;
    > .bemaster-loader {
        width: 25%;
    }
    @media (max-width: $c-breakpoint) {
        width: 100%;
    }
}

.mission-container {
    background-color: #f4f4f4;
    border-radius: 25px 25px 0 0;
    height: 400px;
    position: absolute;
    width: 290px;
    @media (max-width: $c-breakpoint) {
        height: 100%;
        width: unset !important;
    }
    .top-mission-section {
        height: 360px;
        width: 100%;
        @media (max-width: $c-breakpoint) {
            height: 100% !important;
        }
        .mission-title {
            @include theme-aware("background", "color-mission-title-background");
            align-items: center;
            background: transparent;
            border-top-left-radius: 23px;
            border-top-right-radius: 23px;
            height: 50px;
            justify-content: center;
            padding-top: 5px;
            .has-custom-color {
                @include theme-aware("color", "color-missions-title");
                color: $brown;
            }
            @media (max-width: $c-breakpoint) {
                height: 43px;
            }
        }
        .checks-section {
            @media (max-width: $c-breakpoint) {
                margin: 0 !important;
            }
            h1 {
                @include theme-aware("color", "color-mission-item");
                color: $brown;
                font-size: 17px !important;
                @media (max-width: $c-breakpoint) {
                    font-size: 16px !important;
                }
            }
            .mission-item {
                .m-item-container {
                    max-height: 285px;
                    margin-top: 10px;
                    .m-item {
                        align-items: center;
                    }
                }
            }
            .separator {
                border-top: $brown solid 0.6px;
            }
            .comment {
                @include center;
            }
        }
    }

    .bottom-mission-section {
        align-items: center;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        flex-direction: column;
        height: 90px;
        width: 100%;
        z-index: 999;

        .title-rewards-section {
            .reward-title {
                @include theme-aware("display", "reward-title-visible");
                display: unset;
            }
            align-items: center;
            justify-content: center;
            .r-state {
                @include theme-aware("color", "reward-title");
                align-items: center;
                color: white;
                display: flex;
            }
        }
        > .rewards-section {
            flex-direction: column;
            height: 100px;
            width: 280px;
        }
    }

    .reward-claimed {
        @include theme-aware("background-color", "mission-bottom-section");
        background-color: $brown;
    }

    .reward-unclaimed {
        @include theme-aware("background-color", "mission-bottom-section");
        background-color: $green-petroleum;
    }
}
