@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.main-community-container {
    margin-left: 20px;
    width: 90%;

    @media (max-width: $mobile) {
        margin: 60px 0 0 0 !important;
    }

    div h1 {
        font-weight: bold;
    }
    
    .top-section {
        .profile-title {
            @include theme-aware('color', 'color-text');
            color: unset;
            p {
                @include theme-aware('color', 'color-text');
                color: unset;
            }
        }
        @media (max-width: $mobile) {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 10px 0;
            min-height: 35px;
        }
        .navbar-profile {
            @media (max-width: $mobile) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .bottom-section {
        @include theme-aware('background-color', 'color-background-container');
        background-color: white;
        border-radius: 35px;
        height: 100vh;
        margin: 0 auto;
        padding: 50px 180px 0 40px;
        width: 100%;
        @media (max-width: $mobile) {
            border-radius: 15px;
            margin: auto;
            padding: 20px;
            width: 95% !important;
        }
        >.has-loader {
            display: grid;
            height: 55%;
            place-items: center;
    
            img {
                width: 5%;
            }
        }
        >.component-section {
            height: 100vh;
        }
        .is-public-profile-mobile {
            @media (max-width: $mobile) {
                width: 100%;
            }
        }
    }
}

.change-color{
    color: white !important;
}