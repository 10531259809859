@import "../../../components/notification/notification.styles.scss";

.parent-settings-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .child-settings-container {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        cursor: pointer;
        > img,
        i {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 4;
        }
    }

    > .description-content {
        display: flex;
        justify-content: flex-end;
        .is-a-icon {
            @include custom-arrow(16px);
            cursor: pointer;
            margin: 7px auto !important;
            transition: 0.25s;
            z-index: 4;
        }
        .click-away {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.7);
            top: 0;
            left: 0;
            z-index: 3;
            transition: 0.25s;
        }
    }
}
