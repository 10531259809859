@charset "utf-8";

.is-default-textbox {
  height: 32px !important;
  width: 250px !important;
  }  

.center { 
  align-items: center;;
  display: flex;
  justify-items: center;
}

.is-rounded {
  border-radius: 9px !important;
}