@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.navbar-profile-container {
    font-size: 20px !important;
}

.navbar-profile-container div {
    h1 {
        @include theme-aware('color', 'color-text');
        color: unset;
        // font-weight: bold;
        font-size: 14px !important;
    }
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 8px;
}

.navbar-profile-container div:hover {
    background: $dark-gray;
    color: white;
    @media (max-width: $mobile) {
        display: flex;
        justify-content: center;
    }
}

.change-color{
    color: white;
}

.icon-z-index{
    z-index: 100;
}


