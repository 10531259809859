@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.classroom-navbar {
    @media (max-width: $mobile) {
        @include theme-aware('height', 'classroom-mobile-navbar-height');
        height: 60px;
    }
    >.is-nav-section {
        align-items: center;
        color: white !important;
        display: flex;
        font-size: 13px;
        padding: 0px 90px 0 100px !important;
        width: -webkit-fill-available;
        @media (max-width: $mobile) {
            @include theme-aware('justify-content', 'mobile-classroom-navbar');
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            padding: 0% !important;
        }
        >.top-nav-section {
            @media (max-width: $mobile) {
                align-items: center;
                display: flex;  
                justify-content: center;
                margin-top: 17px !important;
                .is-brand-section {
                    margin-left: 5rem;
                    margin-top: 8px;
                }
            }
        }
        >.profile-navbar-fixed {
            @media (max-width: $mobile) {
                justify-content: unset !important;
                margin: 0 auto;
                width: 100%;
                .is-arrow-icon {
                    margin-right: 10%;
                    @media (max-width: 375px) {
                        margin-left: 0% !important;
                    }
                    @media (max-width: 414px) {
                        margin-left: 8% !important;
                    }
                }
            }
        }
        >.classroom-navbar-fixed {
            @media (max-width: $mobile) {
                justify-content: space-around;
                margin-top: 32px !important;
                width: 88%;
            }
        }
        >.support-navbar-fixed {
            @media (max-width: $mobile) {
                margin-top: 10%;
            }
        }
        .is-brand-section {
            display: flex;
            width: 160px;
        }

        .adjust-brand {
            margin-right: 70px !important;
        }
        
        .navbar-start {
            @media (max-width: $mobile) {
                width: 100%;
            }
            .dark {
                color: $dark-gray;
                letter-spacing: 2px;
            }
            .navbar-item {
                @include theme-aware('display', 'workbook-display');
                display: flex;
                @media (max-width: $mobile) {
                    display: flex;
                    margin: auto !important;
                    width: 90%;
                }
            }
            .navbar-item:hover {
                background-color: transparent;
            }
        }
    
        .notification-parent, .settings-parent {
            height: 35px;
        }
    
    }
    
    >.align-arrow {
        @media (max-width: $mobile) {
            margin-top: 15px !important;
        }
    }

}

.is-classroom-navbar {
    @media (max-width: $mobile) {
        height: 100px;
    }
}

.support-nav-fixed {
    @include theme-aware('background', 'support-navbar-background');
    background: unset;
    @media (max-width: $mobile) {
        @include theme-aware('margin-top', 'support-navbar-margin-top');
        @include theme-aware('width', 'support-navbar-width');
        margin-top: 15px !important;
        width: 83% !important;
        .is-arrow-icon {
            margin-right: 40px !important;
        }
    }
}
