@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

@mixin center {
    display: flex;
    justify-content: center;
}

.notification-container {
    @include navbar-basic-container();

    > .ScrollbarsCustom-Thumb,
    .ScrollbarsCustom-ThumbY {
        background: $brown !important;
    }
    > .notifications-list {
        flex-direction: column;
        padding: 0 10px;

    }
    .ScrollbarsCustom{
        height: 180px !important;
    }
    .notification-content {
        min-width: 95% !important;
    }
    @media (max-width: $mobile) {
        left: 6% !important;
        width: 82% !important;
    }
    transition: 0.25s;

    .no-notifications {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        color: gray;
        line-height: 13px;
        position: absolute;
        bottom: 16px;
        font-size: 12px;
    }
}
