@import "../../shared/sass/variables.scss";

.full-screen-video {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    .video-react .video-react-progress-holder .video-react-play-progress {
        background-color: $red !important;
    }
    .video-react .video-react-play-progress:before {
        color: $red !important;
    }
    .video-react .video-react-big-play-button {
        background-color: $red;
    }
    .video-react.video-react-fluid {
        height: 100% !important;
        padding-top: 48% !important;
        position: unset;
        left: 0;
        top: 0;
        @media (max-width: $mobile) {
            height: 100%;
        }
    }
}
