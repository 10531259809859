@import "../../shared/sass/essentials.styles.scss";

@media print {
    body * {
        visibility: hidden;
    }
    #section-to-print,
    #section-to-print * {
        visibility: visible;
        color: white;
        font-size: .5   rem;
    }
    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.main-calc-container {
    background-color: #f5f5f5;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 5rem 35px 5rem 35px;
    position: relative;
    width: 100%;

    h1 {
        color: white;
        font-size: 15px;
        font-weight: 600;
    }

    @include media-hack(sm) {
        margin: auto;
        overflow: scroll;
    }

    .top-label {
        align-items: center;
        background-color: $brown;
        border-bottom-right-radius: 20px;
        display: flex;
        height: 4rem;
        padding: 0 50px;
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;

        @include media-hack(sm) {
            width: 90%;
        }

        h1 {
            color: white;
            font-size: 18px !important;
            font-weight: 700;
        }
    }

    .services-section {
        .service-title {
            align-items: center;
            background-color: black;
            border-radius: 5px;
            color: white;
            display: flex;
            height: 40px;
            margin: 10px 0;
            padding: 15px;
        }
        .fields-section {
            display: flex;
            justify-content: space-between;
            input {
                width: 48%;
            }
        }
        .add-button {
            align-items: center;
            display: flex;
            justify-content: center;
            
            svg {
                background-color: transparent !important;
                border-radius: 50%;
                color: $brown;
                cursor: pointer;
                font-size: 4rem;
                margin: 2rem;
                transition: all ease-in-out 0.32s;

                &:hover {
                    transition: all ease-in-out 0.32s;
                    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
                    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
                }
            }
        }
    }

    .form {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 2rem;
        height: 100%;
        justify-content: space-evenly;
        overflow-y: scroll;
        padding: 2% 0 10% 0;
        width: 100%;

        #a {
            width: 98% !important;
        }

        @include media-hack(sm) {
            flex-direction: column !important;
            height: unset !important;
            overflow-y: unset !important;

            .form-data {
                width: 100% !important;

                .table {
                    .label-section,
                    .input {
                        width: 100% !important;
                    }
                }
            }
        }

        > .form-data {
            display: flex;
            flex-direction: column;
            width: 48%;

            > .table-title {
                align-items: center;
                border-radius: 5px;
                display: flex;
                height: 40px;
                padding: 15px;
            }

            > .table {
                align-items: center;
                background-color: transparent;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                justify-content: space-between;
                margin: 12px 0;
                width: 100%;

                .label-section {
                    align-items: center;
                    background-color: $gray-lighter;
                    border-radius: 4px;
                    display: flex;
                    font-size: 13px;
                    height: 40px;
                    line-height: 15px;
                    padding: 0 10px;
                    width: 48%;
                }

                .input-pack {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 48%;
                    
                    @include media-hack(sm) {
                        width: 100% !important;
                    }

                    .input {
                        color: $gray-darkness;
                    }

                    .field-required {
                        color: $red;
                        font-size: 10px !important;
                        margin: 0 8px !important;
                    }
                }
            }

            .group1 {
                background-color: black;
            }

            .group2 {
                background-color: black;
            }

            .group3 {
                background-color: $brown;
            }
        }

        > .bottom-calc-section {
            align-items: center;
            display: flex;
            grid-gap: 1rem;
            justify-content: center;
            margin: 3rem 0;
            width: 100%;
            
            @include media-hack(sm) {
                display: block !important;
            }

            .is-custom-button {
                @include media-hack(sm) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
