@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.has-custom-background {
    @include theme-aware("background", "color-background");

    background: $grey-lighter;
    > .hero {
        @media (max-width: $breakpoint) {
            display: flex;
        }
    }
}

h1 {
    font-size: 13px !important;
}

.main-container {
    height: 90%;
    margin-top: -30px;
    width: 100%;

    @media (max-width: $breakpoint) {
        margin-top: 0px !important;
    }

    .testing-scrollbar {
        overflow-x: hidden !important;
        @media (max-width: $breakpoint) {
            overflow-x: hidden !important;
        }
    }

    .toggle-missions-layer {
        @media (max-width: $breakpoint) {
            top: 225px;
            position: relative;
            width: 100%;
            z-index: 1;
        }
    }
    .toggle-button {
        display: none;
        @media (max-width: $breakpoint) {
            align-items: center;
            background-color: $brown;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            color: white;
            display: flex;
            font-size: 40px;
            height: 50px;
            justify-content: center;
            right: 0;
            top: 50%;
            position: fixed;
            width: 30px;
            z-index: 10;
        }
    }
    .class-title-section {
        align-items: center;
        color: $dark-gray;
        font-weight: bold;
        margin-top: unset;

        @include theme-aware("color", "color-text");
        @include theme-aware("margin-top", "class-title-margin-top");
        @media (max-width: $breakpoint) {
            font-size: 9px !important;
            line-height: 12px;
            margin: 15px 5px !important;
        }

        .class-link:hover {
            color: $brown;
            cursor: pointer;
        }
    }
    .main-content-section {
        height: 100%;
        width: 98%;

        .custom-scroll {
            height: 98% !important;
            width: 100% !important;
            
            .ScrollbarsCustom-Wrapper {
                @media (max-width: $breakpoint) {
                    width: 100%;
                }
            }
            .ScrollbarsCustom-Track,
            .ScrollbarsCustom-Thumb {
                background-color: transparent !important;
                @media (max-width: $breakpoint) {
                    width: "100%" !important;
                }
                //TODO: Review this temp fixed!
            }
        }
        .component-section {
            height: 700px;
            margin-bottom: 8%;
            @media (max-width: $breakpoint) {
                width: 100%;
            }
        }
        .is-public-profile-mobile {
            @media (max-width: $breakpoint) {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }

        .video {
            min-height: 470px;
            @media (max-width: $breakpoint) {
                margin: 10px;
                min-height: auto;
            }
        }

        .vimeo {
            margin: 10px 0 20px 0;
            min-height: 425px;
            @media (max-width: $breakpoint) {
                margin: -70px 0;
            }
        }

        .class-info {
            .titles-section {
                @include theme-aware("color", "color-class-info");
                @include theme-aware("border-color", "color-class-info");

                align-items: flex-end;
                border-bottom: solid 0.5px $brown;
                color: $brown;
                display: flex;
                height: 50px;
                margin: 0;
                width: 100%;

                .info-title {
                    padding: 0;
                }
                .rating-title {
                    padding: 0;
                }

                @media (max-width: $mobile) {
                    height: auto;
                    margin-top: 1rem;
                }
            }
            .descriptions-section {
                margin-bottom: 30px;
                margin-top: 3px;
                // max-height: 125px;
                .class-description {
                    @include theme-aware("color", "color-class-description");
                    color: $dark-gray;
                }
                > .rating-section {
                    @include theme-aware("color", "color-class-description");
                    color: $dark-gray;
                    margin-left: 30px;
                    @media (max-width: $breakpoint) {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        line-height: 40px;
                        margin: 0 0 180px 0 !important;
                    }
                }
            }
            .related-info {
                color: $dark-gray;
            }
        }

        .missions-collapsed {
            @media (max-width: $breakpoint) {
                background-color: #f2f2f2c7;
                // margin-left: -85%;
                margin-left: -100%;
                left: 100%;
            }
        }

        .missions-uncollapsed {
            @media (max-width: $breakpoint) {
                margin-left: 0%;
                left: 100%;
            }
        }

        .right-area {
            align-items: center;
            display: flex;
            font-size: 13px;
            flex-direction: column;
            height: 470px;
            width: 290px;

            @media (max-width: $breakpoint) {
                align-items: center;
                display: flex;
                height: 100vh;
                top: 0;
                transition: all ease-in-out 1s;
                padding-top: 5%;
                position: fixed;
                right: 0;
                width: 100% !important;
                z-index: 1;
            }

            .missions-panel {
                height: 100%;
                max-height: unset !important;
                @media (max-width: $breakpoint) {
                    max-height: 435px !important;
                }
                
                .missions-section {
                    height: 100%;
                    width: 100%;
                    
                    @media (max-width: $breakpoint) {
                        width: auto !important;
                    }
                    .missions {
                        @include boxShadow(3px, 3px, 15px, 0px, 0.45);
                        border-radius: 25px;
                        height: 370px;
                        position: absolute;
                        @media (max-width: $breakpoint) {
                            height: auto !important;
                            margin: auto;
                            width: unset !important;
                            overflow: hidden;
                        }
                    }
                    .workbook-button button {
                        width: 85% !important;
                        margin: auto;
                    }
                }
                .missions-section-collapsed {
                    @media (max-width: $breakpoint) {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .prev-next-buttons {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    width: 290px;
                    line-height: 14px;
                    a {
                        @include theme-aware("color", "prev_next_button");
                        color: unset;
                        cursor: pointer;
                    }

                    .prev-button {
                        @include theme-aware("color", "prev-next-buttons");
                        align-items: center;
                        border-right: solid 1px;
                        // cursor: pointer;
                        color: unset;
                        display: flex;
                        justify-content: center;
                        width: 120px;

                        @media (max-width: $breakpoint) {
                            color: $gray-darkness !important;
                        }
                    }
                    .next-button {
                        @include theme-aware("color", "prev-next-buttons");
                        align-items: center;
                        // cursor: pointer;
                        display: flex;
                        justify-content: center;
                        width: 90px;

                        @media (max-width: $breakpoint) {
                            color: $gray-darkness !important;
                        }
                    }

                    .disabled {
                        color: #909090da;
                        cursor: default;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    .enabled {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .has-custom-size {
        font-size: 15px;
    }
}
