@import '../../shared/sass/essentials.styles.scss';

.success {
    background-color: #d9f4e1 !important;
    border-color: #0db80d56 !important;
    color: $gray-darkness !important;
    font-weight: 500;
}

.error {
    background-color: #b73f3944 !important;
    border-color: #b73f396c !important;
}

.common-input {
    border-color: $gray-lighter;
}
