@import "../../shared/sass/variables.scss";

.class-card {
    width: 100%;
    .is-card {
        h2 {
            font-weight: bold;
            margin-bottom: .5rem;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            width: 100%;
        }
        .class-card-container {
            align-items: flex-start;
            background-color: $gray-progress;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            line-height: 20px;
            min-height: 110px;
            padding: 15px 20px;
            margin: 10px;
            width: -webkit-fill-available;
            position: relative;
            @media (max-width: $mobile) {
                width: 95% !important;
            }

            .check {
                position: absolute;
                right: 0px;
                bottom: 16px;
                color: white;
                width: 50px;
                height: 24px;
                background-color: rgba(100, 200, 150, 1);
                border-radius: 5px 0px 0px 5px;
            }
        }
    }
}
