@import '../../shared/sass/variables.scss';

.main-video-container {
    height: 480px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: $grey-dark;
    .top-logo {
        width: -webkit-fill-available;
        margin-top: 36px;
        margin-left: 131px;
        position: absolute;
        top: 0;
        z-index: 2;
        img { 
            height: 15px;
            width: 150px;
        }
    }
}