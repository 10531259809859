@import '../../shared/sass/variables.scss';

.main-progress-container {
    width: 100%;
    .top-progress-container {
        align-items: center;
        background-color: $grey-dark;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        color: white;
        display: flex;
        height: 100px;
        justify-content: center;
        @media (max-width: $mobile) {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }
    .down-side {
        align-items: center;
        display: flex;
        height: 60vh;
        justify-content: center;
        .ScrollbarsCustom-Content {
            @media (max-width: $mobile) {
                padding-bottom: 150px !important;
            }
        }
        .ScrollbarsCustom-Track, .ScrollbarsCustom-TrackY {
            background-color: transparent !important;
            .ScrollbarsCustom-Thumb, .ScrollbarsCustom-ThumbY {
                background-color: transparent !important;
            }
        }
        .ScrollbarsCustom, .trackYVisible {
            background-color: transparent !important;
            .ScrollbarsCustom-Wrapper {
                background-color: transparent !important;
                position: absolute;
                top: 0px;
                left: 30px !important;
                bottom: 0px;
                right: -80px !important;
                //TODO: Fix this stuff
                // overflow: hidden;
                @media (max-width: $mobile) {
                    width: 84% !important;
                }
            }
        }


    }
}