@import '../../../shared/sass/variables.scss';
@import '../../../shared/sass/essentials.styles.scss';

// @debug map-get(map-get($themes, 'light'), 'color-background');

// @use "sass:map"
// map-get(map-get($themes, 'light'), 'color-background');

.mail-support-container {
    // Get value from nested maps
    h1 {
        @include theme-aware('color', 'color-text');
        color: unset;
    }
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    @media (max-width: $mobile) {
        justify-content: flex-start;
        overflow-y: scroll;
        padding-bottom: 250px;
        width: 100%;
    }
    .has-form-content-area {
        margin-bottom: 13%;
        width: 45%;
        @media (max-width: $mobile) {
            width: 90%;
        }
    }
    .confirmation-modal {
        align-items: center;
        background-color: $green-petroleum;
        border-radius: 15px;
        display: flex;
        height: 150px;
        justify-content: center;
        margin-bottom: 15%;
        padding: 10px;
        text-align: center;
        width: 408px;
    }
    .question-area {
        .textarea {
            background-color: $gray-lighter;
            border-radius: 20px;
            height: 200px;
            @media (max-width: $mobile) {
                height: 100px;
            }   
        }
        .textarea:focus {
            border-color: #f0f0f0;
        }
    }
    .attachment-area {
        align-items: center;
        border-radius: 5px;
        border: dashed 2px grey;
        color: gray;
        cursor: pointer;
        display: flex;
        font-size: 35px;
        justify-content: center;
        height: 60px;
        width: 100%;
    }
    .btn-area {
        align-items: center;
        display: flex;
        justify-content: center;
        .class-button {
            margin-top: 10px !important;
            margin-bottom: 30px !important;
        }
    }
}