@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

@mixin custom-arrow($mr: 8px) {
    @include theme-aware("background-image", "arrow-icon-elite");
    background-image: url("https://cdn.bemaster.com/media/icons/home/Sitiobemaster34.svg");
    background-size: cover;
    height: 18px;
    transform: rotate(90deg);
    margin: auto;
    width: 12px;
}

.parent-container {
    .child-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        > .icons-section {
            align-items: center;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 5;

            @media (max-width: $mobile) {
                svg {
                    height: 32px;
                    width: 32px;
                }
            }

            svg {
                color: $brown;
                cursor: pointer;
            }

            > .notification-amount {
                align-items: center;
                background-color: #b73f39;
                border-radius: 30px;
                cursor: pointer;
                color: white;
                display: flex;
                font-size: 0.6rem;
                font-weight: 600;
                height: 14px;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                width: 14px;
            }
        }
        > .is-notification-icon {
            cursor: pointer;
            height: 28px !important;
            z-index: 5;
        }
    }
    .description-content {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .is-a-icon {
            @include custom-arrow();
            z-index: 1;
            transition: 0.25s;
            cursor: pointer;
            z-index: 4;
        }

        .click-away {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.7);
            top: 0;
            left: 0;
            z-index: 3;
            transition: 0.25s;
        }
    }
}
