@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.event-card-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    min-height: 113px;
    min-width: 100% !important;
    transition: all ease-in-out 0.32s;

    &:hover {
        @include boxShadow(3px, 3px, 15px, 0px, 0.45);
        border: 1px solid $brown;
        transition: all ease-in-out 0.32s;
        // transform: scale(1.02);
    }

    @media (max-width: $mobile) {
        min-height: 155px;
        background-position: bottom;
        min-height: 170px !important;
    }

    > .is-date-area {
        border-radius: 10px;
        display: flex;
        color: white;
        width: 20%;
        @media (max-width: $mobile) {
            width: 53%;
        }
        .date-area {
            align-items: center;
            display: flex;
            height: 100%;
            padding: 10px;
            .is-icon-area {
                height: 100%;
            }
        }
    }
    > .is-text-area {
        align-items: center;
        display: flex;
        width: 250px;
        .fc-icon {
            color: white;
            font-size: 26px;
            height: 0px;
            width: 110px;
        }
    }
}

// Background by level
.junior {
    background-color: $red;
}

.senior {
    background-color: $green-petroleum;
}

.master {
    background-color: $brown;
}
