@import "../../shared/sass/variables.scss";

.has-background-image {
  background-image: url("https://cdn.bemaster.com/media/images/shared/Imagendefondonegro.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.is-front-layer {
  // background-color: #020202c4 !important;
  position: relative;
  @media (max-width: $mobile) {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;

    //TODO: Verify important implementation
  }
}

.is-back-layer {
  position: fixed;
  width: -webkit-fill-available;
}

.has-cards-inside {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1.5rem;
  text-align: center;
}

.is-brand-section {
  margin-left: 15px;
}

.is-main-container {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  @media (max-width: $mobile) {
    width: -webkit-fill-available;
  }
  .is-title-section {
    font-weight: bold;
    margin-bottom: 2.3rem !important;
  }
}
