@import '../../shared/sass/variables.scss';

.main-product-container {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    // @media (max-width: $mobile) {
    //     display: grid;
    //     grid-template-columns: 1fr;
    //     grid-gap: 30px;
    //     width: 100%;
    // }
    .product-container {
        display: flex;
        width: 340px;
        height: 190px;
        @media (max-width: $mobile) {
            width: 100%;
        }
        .p-card {        
            border-radius: 10px;
            height: 100%;
            width: 50%;
        }
        .details-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding-left: 15px;
            width: 50%;
            .mini-price {
                h1 {
                    color: $brown;
                    font-weight: bold;
                }
                align-items: center;
                display: flex;
            }
            h1 {
                color: white;
                line-height: 20px;
            }
        }
    }
}

// CDN Simulation
.fire-bag {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: $mobile) {
        height: 25px !important;
    }
}