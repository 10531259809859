@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.preferences {
    @media (max-width: $mobile) {
        width: 97% !important;
    }
    .ScrollbarsCustom-Content {
        @media (max-width: $mobile) {
            // padding-bottom: 180px !important;
        }
    }
    .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
        background-color: transparent !important;
    }
    .top-section {
        border-bottom: 1px solid $form-border;
        @media (max-width: $mobile) {
            display: flex;
            flex-direction: column;
        }
        .check-div {
            @media (max-width: $mobile) {
                border-radius: 15px;
                display: flex;
                height: 90px;
                padding: 15px !important;
                width: 100% !important;
            }
            align-items: center;
            background-color: $gray-lighter;
            border-radius: 25px;
            display: flex;
            height: 35px;
            width: 500px;

            .link a {
                text-decoration: underline;
            }
        }
        .terms-div {
            a {
                text-decoration: underline;
            }
        }
    }
    .p-bottom-section {
        .data {
            h3 {
                @include theme-aware('color', 'color-text');
                color: unset;
            }
        }
        .preferences {
            .top-section {
                .terms-div {
                    a {
                        @include theme-aware('color', 'color-text');
                        color: unset;
                    }
                }
            }
        }
    }
}