@import '../../shared/sass/variables.scss';

@mixin center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.module-card {
    @include center;
    padding: 0 0 30% 0px;
    @media (max-width: $mobile) {
        // padding: 0 0 70% 0px;
    }
    .module {
        align-items: center;
        @include center;
        
        .main-module-container {            
            background-position: center center;
            background-size: cover;
            border-radius: 13px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 150px;
            padding: 0 30px;
            transition: all ease-in-out 0.32s;
            width: 90%;
            
            .module-title {
                color: white;
                font-weight: bold;
                margin-bottom: -10px;
                @media (max-width: $mobile) {
                    font-size: 1.5rem !important;
                }
            }
            .module-name {
                color: $gray-lighter;
            }
            &.main-module-container:hover {
                border: 1px solid $brown;
                transform: scale(1.02);
            }
        }
        .wrap-container {
            align-items: center;
            background-color: $gray-progress;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
            display: flex;
            justify-content: center;
            width: 88%;
            transition: all ease-in-out 0.6s;
            .modules-resume {
                padding: 13px;
                @media (max-width: $mobile) {
                    width: 100%;
                }
            }
        }
    }
}

