@import "../../../shared/sass/variables.scss";

.ReactModalPortal {
    .ReactModal__Overlay--after-open {
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            background-color: transparent !important;
            border-radius: 20px !important;
            margin-bottom: 20% !important;
            margin-top: 7% !important;
            margin-left: 27% !important;
            margin-right: 27% !important;
            overflow: hidden;

            @media (min-width: $big-screen) {
                background-color: $gray-lighter !important;
                border-radius: 20px !important;
                margin-bottom: 20% !important;
                margin-top: 7% !important;
                margin-left: 32% !important;
                margin-right: 32% !important;
            }

            @media (max-width: $breakpoint) {
                background-color: $gray-lighter !important;
                border-radius: 20px !important;
                margin-bottom: 20% !important;
                margin-top: 7% !important;
                margin-left: 25% !important;
                margin-right: 25% !important;
            }

            @media (max-width: $mobile) {
                border-radius: 10px !important;
                margin-top: 40% !important;
                margin-left: 10% !important;
                margin-right: 10% !important;
            }
        }
    }
}

.basic-modal-container {
    -webkit-animation: bounce-in 1.1s both;
    animation: bounce-in 1.1s both;

    .basic-child-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3rem;
        position: relative;

        .main-title {
            margin: 0 0 10px 0;

            h1 {
                font-size: 1.5rem !important;
                font-weight: 700;
            }
        }

        .close-button-section {
            position: absolute;
            right: 0;
            top: 0;

            .close-icon {
                cursor: pointer;
                display: flex;
                font-size: 25px;
                justify-content: flex-end;
                margin: 10px;
            }
        }

        .main-description {
            font-size: 20px;
            line-height: 1.5rem;
            text-align: center;
        }
    }

    //Container PopUp Customization
    .info-success-container {
        background-color: #e3fcef;
    }

    .info-fail-container {
        background-color: rgb(255, 235, 230);
    }

    .info-info-container {
        background-color: #2196f336;
    }

    //Text PopUp Customization
    .info-success {
        color: rgb(0, 102, 68) !important;
    }

    .info-fail {
        color: rgb(191, 38, 0) !important;
    }

    .info-info {
        color: rgb(88 150 255 / 96%) !important;
    }
}
