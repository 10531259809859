@import '../../shared/sass/essentials.styles.scss';
@import '../../shared/sass/variables.scss';

.is-triumph-section {
    .is-triumph-list {
        align-items: center;
        display: center;
        justify-content: center;
        margin-bottom: 50px;
    }

    @media (max-width: $mobile) {
        margin: 0 !important;
    }
}