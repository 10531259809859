@import '../../shared/sass/variables.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.rewards-container {
    @include center;
    height: 30px;
    flex-direction: row;
    .rewards-description {
        .r-text {
            @media (max-width: $mobile) {
                font-size: 15px !important;
            }
        }
    }
    .icons-section {
        @include center;
        align-items: center;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        width: 50px;
    }
 
}