.main-powerclass-item-container {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
    gap: 1rem;
    overflow: hidden;
    
    &:hover ~ .text-container {
        width: 100px;
    }

    .icon-container {
        align-items: center;
        display: flex;

        > img {
            fill: #C19656 !important;
            height: 16px;
            weight: 16px;
        }
    }
    
    .text-container {
        font-weight: 300;
        font-size: 10px;
        letter-spacing: 4px;
        max-height: 20px;
        transition: all ease-in-out 0.35s;
        width: 0;
    }
}
