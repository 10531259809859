.custom-slider {
    height: 390px;
    padding: 0 28px 0 30px;
    width: 280px;

    .child-slider {
        .slick-list {
            padding-left: 5px;
            margin-bottom: -5px;
        }
        .slick-dots li.slick-active button:before {
            opacity: 0.75;
            color: white;
        }
        .slick-dots li button:before {
            color: #909090;
        }
    }
}

.cell {
    height: 90px;
    outline: none;
}
