@import '../../shared/sass/variables.scss';

.video-react-player {
    min-height: 250px !important;
    width: 100%;
    
    @media (max-width: $mobile) {
        min-height: unset !important;
    }
}

.video-react .video-react-big-play-button {
    background-color: $red !important;
}

.video-react .video-react-progress-holder .video-react-play-progress {
    background-color: $red !important;
}

.video-react .video-react-big-play-button.video-react-big-play-button-custom {
    top: 80% !important;
    left: 16% !important;
    margin-top: -.75em !important;
    margin-left: -1.5em !important;
}
    
.video-react .video-react-play-progress:before {
    color: $red !important;
}

.video-react .video-react-big-play-button {
    background-color: $red !important;
}

.nextVideoButton {
    cursor: pointer;
}

.controlBar {
    font-size: 12px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%) !important;
    height: 40px !important;
}