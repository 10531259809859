@import "../../shared/sass/variables.scss";

@mixin center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.workbook-container {
  background-color: white;
  border-radius: 25px;
  height: 100%;
  margin-left: 24px;
  position: relative;
  @media (max-width: $mobile) {
    height: 100%;
    min-width: unset !important;
    margin: auto;
  }

  > .loader-container {
    width: 80px;
    height: 80px;
    top: 40%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;

    > img {
      width: 100%;
    }
  }

  > .title-area {
    position: relative;
    background-color: $blue;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    width: 100%;
    height: 100px;
    font-size: 32px;

    @media (max-width: $mobile) {
      align-items: center;
      display: flex;
      height: 60px;
      font-size: 18px;
      padding: 0 1rem;
    }

    > .workbook-title {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 2rem;

      @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
      
        svg {
          max-width: 18px;
        }
      }

      .editable-title {
        align-items: center;
        display: flex;

        > input {
          border: none;
          background: transparent;
          font-size: 32px;
          color: white;
          font-weight: bolder;
  
          @media (max-width: $mobile) {
            font-size: 14px !important;
          }
        }
      }
      
      > .completed-at-title {
        font-size: 16px;
        
        @media (max-width: $mobile) {
          font-size: 10px !important;
        }
      }
    }

    > .workbook-tools {
      align-items: center;
      background: #1a2d44;
      border-radius: 25px;
      height: 60%;
      justify-content: space-evenly;
      position: absolute;
      right: 16px;
      top: 20%;
      display: flex;
      width: 30%;

      @media (max-width: $mobile) {
        padding: 0 15px 0 0 !important;
        width: 45%;
        svg {
          min-width: 20px;
        }
      }

      > .workbook-tool {
        cursor: pointer;

        @media (max-width: $mobile) {
          margin: 0 5px !important;
        }
      }

      > .save {
        width: 128px;
        height: 32px;
        background: #18a757;
        padding: 8px;
        text-align: center;
        font-size: 12px;
        color: white;
        border-radius: 16px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          background: white;
          border: 1px solid #18a757;
          color: #18a757;
        }
      }

      > .block {
        background: gray;
        color: black;
      }
    }
  }

  > .workbooks-list {
    width: 100%;
    height: 80%;
    padding: 32px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;

    > .workbook {
      height: 250px;
      margin-top: 32px;
      width: 33.3%;

      @media (max-width: $mobile) {
        width: 100%;
      }

      > .workbook-create {
        place-items: center;
        width: 70%;
        height: 100%;
        margin: auto;
        border-radius: 32px;
        background: #e0e0e0;
        font-size: 64px;
        text-align: center;
        display: grid;
        color: rgb(80, 80, 80);
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          transition: all ease-in-out 0.32s;
          -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
          -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
        }
      }

      > .workbook-content {
        width: 80%;
        height: 100%;
        margin: auto;
        overflow: hidden;
        border-radius: 28px;
        position: relative;
        transition: 0.5s;
        border: 1px solid rgb(200, 200, 200);

        &:hover {
          transition: all ease-in-out 0.32s;
          -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
          -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
        }

        > .workbook-mini {
          width: 100%;
          height: calc(100% - 100px);
          position: absolute;
          padding: 15px;
          overflow-y: auto;
          overflow-x: hidden;

          form {
            pointer-events: none;
            font-size: 4px !important;

            div {
              font-size: 8px !important;
            }

            textarea {
              font-size: 8px !important;
              height: 30px;
              resize: none;
              width: 60%;
            }

            button,
            input {
              font-size: 8px !important;
              pointer-events: none;
            }
          }
        }

        > .workbook-name {
          width: 100%;
          height: 32px;
          background: $blue;
          text-align: center;
          display: grid;
          place-items: center;
          color: white;
          font-size: 0.8rem;
          font-weight: bolder;
        }

        > .workbook-options {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 64px;
          background: $blue;

          .workbook-tools {
            position: absolute;
            right: 8px;
            top: 8px;
            .workbook-tool {
              color: white;
              cursor: pointer;
            }
          }

          > button {
            top: 8px;
            left: 8px;
            position: absolute;
            font-weight: bolder;
            width: 80px;
            height: 28px;
            border: none;
            background: $brown;
            padding: 8px;
            font-size: 12px;
            color: white;
            border-radius: 16px;
            cursor: pointer;
          }

          > .completed-at {
            color: white;
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 4px;
            font-size: 12px;
          }
        }
      }
    }
  }

  > .workbook-content-section {
    align-items: center;
    flex-direction: column;
    height: $work-section-height;
    padding: 20px 31px;
    width: 100%;
    .brand-section {
      @include center;
      .brand-image {
        width: 8%;
      }
      .brand-logo {
        width: 20%;
      }
    }
    .w-content {
      .homework-section {
        border-right: 1px solid $grey-dark;
      }
      .world-image {
        width: 40%;
      }
    }
  }
}
