@import '../../shared/sass/essentials.styles.scss';
@import '../../shared/sass/variables.scss';


.back-button-main-container {
    >.back-arrow {
        color: white;
        cursor: pointer;
        font-size: 35px !important;
    }

    @media (max-width:$big-screen){
        display: none;
    }
}
