@import '../../../sass/variables.scss';
@import '../../../sass/essentials.styles.scss';

.is-black-logo {
    @include theme-aware('width', 'logo-width'); 
    @include theme-aware('margin-top', 'logo-margin-top'); 
    @include theme-aware('margin-bottom', 'logo-margin-bottom'); 
    margin-top: 0;
    margin-bottom: 0;
    width: 100% !important;

    // background: 'https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png';
    // img {
    // }

// alonsoach1998@gmail.com

}