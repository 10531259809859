@import '../../shared/sass/variables.scss';

.main-store-container {
    background-color: $black;
    border-top-left-radius: 20px;  
    border-top-right-radius: 20px;
    height: 100%;
    width: 94%;
    @media (max-width: $mobile) {
        height: auto;
        margin: auto;
    }
    .mega-banner {
        background-image: url('https://cdn.bemaster.com/media/images/fires-store/fires-banner.png');
        border-top-left-radius: 20px;  
        border-top-right-radius: 20px;
        height: 310px;
        @media (max-width: $mobile) { 
            background-image: url('https://cdn.bemaster.com/media/images/fires-store/fires-banner-sm.png');
            height: 150px !important;
        }
        .content-area {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 400px;
            h1 {
                line-height: 43px;
            }
            @media (max-width: $mobile) {
                margin: 0 !important;
                width: 100%;
            }
        }
    }
    .bottom-shop-section {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 40px 20px;
        width: 100%;
        @media (max-width: $mobile) {
            padding: 30px;
        }
    }
}

// CDN Simulation
.productBanner {
    background-image: url('https://cdn.bemaster.com/media/images/fires-store/fires-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: $mobile) {
        background-position: center center;
        height: 30% !important;
    }
}