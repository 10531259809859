@charset "utf-8";

.grecaptcha-badge { visibility: hidden; }

.main-begining-container {  
  .center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .is-front-layout {
    align-items: center;
    background-color: #020202c4 !important;
    display: flex;
    justify-content: center;
    position: relative;
    min-width: max-content;
  }
  
  .hasImgBac {
    background-image: url('https://cdn.bemaster.com/media/images/login/Imagendefondo.jpg') !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}
