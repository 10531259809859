@import '../../shared/sass/variables.scss';

.shop-modal {
    .close-button {
        cursor: pointer;
        font-size: 30px;
        top: 0;
        position: absolute;
        right: 5px;
    }
    .shop-text {
        color: $brown;
        font-weight: bold;
    }
    .ReactModal__Overlay--after-open { 
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            align-items: center;
            background-color: $gray-lighter !important;
            border-color: $gray-darkness !important;
            border-radius: 20px !important; 
            bottom: 37% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 37% !important;
            left: 31% !important;
            right: 31% !important;
            overflow: hidden !important;        
            @media (max-width: $mobile) {
                bottom: 42% !important;
                right: 10% !important;
                left: 10% !important;
                top: 37% !important;
                .shop-text {
                    font-size: 20px !important;
                }
                .content-text {
                    font-size: 15px !important;
                    text-align: center;
                }
            }
        }
    }
}