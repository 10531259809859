@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.home-carousel {
    margin: 15px 0 50px 0;

    & > button {
        opacity: 0;
        height: 100%;
        width: 5vw;
        z-index: 1;

        &:hover {
            opacity: 1;
            transition: opacity 0.2s ease 0s;
        }
    }

    ul li button {
        &:before {
            font-size: 10px;
            color: rgb(150, 158, 171);
        }
    }

    li.slick-active button:before {
        color: white !important;
        height: 20px;
    }

    .slick-slider {
        width: 100%;
        margin-right: 98px !important;
    }

    .slick-list {
        overflow: initial;
        z-index: 0;
    }

    .slick-prev {
        color: white !important;
        margin: 0 2rem !important;
        left: 0 !important;
        height: 20px;
        width: 20px;
        z-index: 1;
    }

    .slick-next {
        color: white !important;
        margin: 0 2rem !important;
        right: 0 !important;
        z-index: 1;
    }

    > .wrap {
        border-radius: 4px;
        cursor: pointer;
        position: relative;
    }
}

.has-background-video {
    @include theme-aware("background-image", "banner-background");
    @include theme-aware("height", "main-home-banner-height");
    background-position-y: 80%;
    background-size: cover;
    border-radius: 1.5rem;
    cursor: pointer;
    height: 300px;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;

    @media (max-width: $mobile) {
        @include theme-aware("background-image", "banner-home-mobile-background");
        background-position: center !important;
        height: 310px;
    }

    > .is-info-section {
        @include theme-aware("display", "banner-info-section");
        display: unset;
        margin: auto 0;
        width: 550px;

        @media (max-width: $mobile) {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        > .video-title {
            @media (max-width: $mobile) {
                text-align: center;
            }
            line-height: 40px;
            > .is-be-name {
                letter-spacing: 8px;
                font-weight: 300;
            }

            > .is-career-name {
                letter-spacing: 8px;
                font-weight: bold;
            }
        }
        p {
            font-weight: 100;
            width: 315px;
            @media (max-width: $mobile) {
                padding: 0 20px 0 20px;
                width: auto;
            }
        }
    }
}

.has-down-info {
    @media (max-width: $mobile) {
        background-position: center;
    }
}
