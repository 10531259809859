
@import '../../../../shared/sass/variables.scss';
@import '../../../card/card.styles.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-items: center;
}

.grecaptcha-badge { visibility: hidden; }

.is-main-container {

    @include center;

    @media (max-width: $mobile) {
        margin: 0 !important;
    }
    .is-login-section {
        @include center;
        background: $main-window-background;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        min-height: 260px;
        margin: 0 25px;
        padding-bottom: 110px;
        width: 390px;
        @media (max-width: $mobile) {
            margin: 0 !important;
            padding-bottom: 70px !important;
            width: 290px !important;
        }
        >.rec-sec {
            height: 70px;
            margin: 0 !important;
            text-align: center;
        }
        >.is-logo-section {
            margin: 33px;
            @include center;
            .image {
                align-items: center;
                display: flex;
                justify-content: center;
                .image-this {
                    width: 25%;
                }
            }
        }
        >.is-login-button-section {
            height: 37px;
            width: 250px;
        }
        .email-required {
            color: $red;
        }
    }

    .has-text-white {
        color: white;
    }
    
    .is-external-login-section {
        flex-direction: column;
        align-items: center;
        display: flex;
    }

    .is-credentials-section {
        flex-direction: column;
        width: 250px !important;
    }

    .is-register-section {
        font-size:12px !important;
        text-align: center !important;
        max-width: 205px;
    }

    .has-loader {
        height: 60px;
        margin-left: 33%;
        margin-top: 15%;
        margin-bottom: -48%;
        width: 60px;
        @media (max-width: $mobile) {
            height: 45px;
            margin-bottom: -25%;
            margin-left: 40%;
            margin-top: 5%;
            width: 45px;
        }
    }

    .recovery {
        @include center;
        padding-top: 20px;
        padding-bottom: 0px !important;
        @media (max-width: $mobile) {
            padding-top: 5px !important;
        }
        .exit-button-section{
            color: rgb(150, 150, 150);
            display: flex !important;
            font-size: 35px;
            justify-content: flex-end;
            margin-top: -20px;
            width: inherit;
            @media (max-width: $mobile) {
                color: #969696;
                display: flex !important;   
                font-size: 27px;
                justify-content: flex-end;
                margin-top: 0px;
                width: inherit;
            }
            .close-icon {
                cursor: pointer;
            }
        }
        .remind-credentials {
            max-width: 100%;
            .styled{
                text-decoration: underline;
            }
        }
    }  
}
