@import '../../../../shared/sass/variables.scss';

.main-resolution-button {
    cursor: pointer;
    font-size: 16px;
    margin-top: 7px;
    position: relative;

    .click-away {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        z-index: 10
    }

    .resolution-options {
        position: absolute;
        bottom: 50px;
        right: -20px;
        background-color: rgba(100, 100, 120, .5);
        padding: 8px;
        border-radius: 5px;
        z-index: 20;
    }
    
    .available-resolutions {
        position: absolute;
        bottom: 50px;
        right: 100px;
        background-color: rgba(100, 100, 120, .5);
        padding: 8px;
        border-radius: 5px;
        z-index: 20;
    }
}

.resolution-option {
    font-family: 'Gotham', sans-serif !important;
    padding: 2px 16px;

    &:hover {
        background-color: rgba(100, 100, 120, .5);
    }
}