@import '../../shared/sass/variables.scss';

.reward-modal {
    .close-button {
        cursor: pointer;
        font-size: 30px;
        top: 0;
        position: absolute;
        right: 5px;
        z-index: 5000 !important;
    }
    .congrats-text {
        color: $brown;
        font-weight: bold;
    }

    .ReactModal__Overlay--after-open { 
        background-color: rgb(255 255 255 / 24%) !important;
        justify-content: center;
        z-index: 1010 !important;
        
        .ReactModal__Content--after-open {
            align-items: center;
            background-color: black !important;
            border-color: black !important;
            border-radius: 20px !important;
            bottom: 22% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 34% !important;
            left: 31% !important;
            right: 31% !important;
            overflow: hidden !important; 
            
            box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.75);
            -webkit-box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.75);
        
            @media (max-width: $mobile) {
                left: 5% !important;
                right: 5% !important;
                .mess {
                    font-size: 15px !important;
                    text-align: center;
                    margin-bottom: 65px !important;
                }
            }      
        }
    }
}