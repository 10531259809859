@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.container-link {
  @media (max-width: $mobile) {
    height: 100% !important;
    margin: 10px 0;
  }
  .master-card-container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 195px;

    @media (max-width: $mobile) {
      width: 100% !important;
    }

    .stars-rating {
      position: absolute;
      top: 15%;
      right: 8px;
    }

    .master-card {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 12px;
      cursor: pointer;
      display: flex;
      height: 90%;
      margin: auto;
      overflow: hidden;
      position: relative;
      transition: transform 0.2s;
      width: 90%;

      @media (max-width: $mobile) {
        height: 200px;
      }

      video {
        border-radius: 10px;
        height: 100%;
        opacity: 0;
        position: relative;
        transition: opacity 0s;
        cursor: pointer;
        width: 100%;
        @media (max-width: $mobile) {
          display: none !important;
          opacity: 0 !important;
        }
      }

      .default-layout {
        align-items: center;
        color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 10px 5px 30px 5px;
        position: absolute;
        width: 100%;

        h1 {
          font-weight: 1000 !important;
          font-size: 14px !important;
          line-height: 20px;
          text-align: center;
        }
        img {
          top: 0;
          width: 40%;
        }
      }

      .hover-layout {
        background-color: #00000000;
        background-image: linear-gradient(0deg, #000000d9 0%, #00000000 100%);
        color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        position: absolute;
        opacity: 1;
        width: 100%;

        h1 {
          color: white;
          margin: 0;
        }

        .content {
          padding: 10px;
          h2 {
            color: white;
            font-size: 12px !important;
          }
          .card-actions {
            align-items: center;
            display: flex;
            margin: 5px 0;
            .play-icon {
              align-items: center;
              background-color: $red;
              border-radius: 50%;
              display: grid;
              font-size: 10px;
              justify-content: center;
              place-items: center;
              padding: 5px;
              &:hover {
                background-color: #cb4f49;
              }
            }
            .plus-icon {
              color: $gray-lighter;
              font-size: 20px;
              margin: 0 5px;
              &:hover {
                color: white;
              }
            }
            .rounded-container {
              align-items: center;
              border: 1px solid $gray-lighter;
              border-radius: 30px;
              display: grid;
              font-size: 10px;
              justify-content: center;
              place-items: center;
              padding: 4px;
              &:hover {
                border-color: white;
              }
            }
          }
          .classes-details {
            align-items: center;
            display: flex;
            h2 {
              color: white;
              font-weight: 100;
              font-size: 9px !important;
              margin: 0;
              padding: 2px;
            }
            > .class-quantity {
              align-items: center;
              border: 1px solid white;
              display: flex;
              margin: 0;
              padding: 0;
            }
          }

          .mentor-data {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            .data {
              display: flex;
              margin-top: 5px;
              width: 50%;

              img {
                width: 7%;
                margin-right: 3px;
              }
              h3 {
                color: white;
                font-size: 8px !important;
                margin: 0;
              }
            }
          }

          img {
            width: 15%;
          }
        }

        &:hover {
          opacity: 0;
          transition: opacity 4s 3s;
        }
      }

      .payment-content {
        align-items: center;
        background: $brown;
        bottom: 0;
        display: flex;
        height: 30%;
        justify-content: center;
        position: absolute;
        width: 100%;

        .price-coin {
          margin: 0 5px;
        }

        .price-text {
          color: white;
          font-size: 32px;
          font-weight: 800;
        }
      }

      &:hover {
        transition: transform ease-in 0.32s;
        transform: scale(1.09);
        
        video {
          transform: scale(1.09);
          transition: opacity ease-in-out 0.5s 1s;
          opacity: 1;
        }
      }
    }
  }

  .transition {
    -webkit-transition-duration: .25s;
    -moz-transition-duration: .25s;
    -o-transition-duration: .25s;
    transition-duration: .25s;
    transition-property: transform;
  }
}

// .master-card:hover {
//   transition: .2s;
//   transform: scale(1.05);
//   border: solid 2px $brown;
//   box-shadow: 0 0 5px 5px black;
// }
