@import '../src/shared/sass/essentials.styles.scss';
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';
@import '../src/shared/sass/variables.scss';

body, html {
  font-family: 'Gotham', sans-serif !important;
  height: auto;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

//Post Full Working Autoadjust
html {
  overflow: hidden;
  @media (max-width: 576px) {
    overflow: auto !important;
  }
}