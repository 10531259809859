@import '../../sass/variables.scss';

.form-button {  
    @media (max-width: $mobile) {
        width: 100%;
    }
    .button {
        border-radius: 25px !important;
        @media (max-width: $mobile) {
            width: 100%;
            h1 {
                font-size: 11px !important;
            }
        }
    }
    
    .brown {
        background-color: $brown !important;
    }
    
    .red {
        background-color: $red !important;
    }

    .button:active, .button:focus {
        outline: none;
        border: none;
    }
}