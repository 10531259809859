@import '../../shared/sass/essentials.styles.scss';
@import '../../shared/sass/variables.scss';

.triumphs-resume-container {
    
    // height: 550px;
    height: 80%;
    width: 100%;
    .ScrollbarsCustom-Content {
        @media (max-width: $mobile) {
            padding-bottom: 100px !important;
        }
    }
    
    .ScrollbarsCustom-TrackY {
        // height: calc(100% - 110px) !important;
        height: 100%;
        top: 110px !important;
        background-color: transparent !important;
        .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
            background-color: transparent !important;
        }
    }

    .triumphs-resume {
        .category-title-section {
            @include theme-aware('color', 'color-text');            
            color: unset;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .subtitle {
                color: $brown;
                line-height: 1.5 !important;
            }
        }
        .awards-section {
            display: flex;
            justify-content: center;
        }
    }
}