@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.career-pensum-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
    min-width: 300px;
    width: 100%;
    @media (max-width: $mobile) {
        margin: 0 0 auto 0;
    }
    .superior-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        @media (max-width: $mobile) {
            width: 95%;
        }
        .career-header {
            align-items: center;
            color: white;
            display: flex;
            font-weight: bold;
            justify-content: center;
            width: 100%;
        }
        .progress-state {
            @include theme-aware("display", "hide-progress-bar-pensum");
            display: flex;
        }
    }
    .inferior-section {
        background-color: transparent;
        border-radius: 20px;
        display: flex;
        height: 100vh;
        justify-content: center;
        width: 100%;
        @media (max-width: $mobile) {
            margin-top: -10px !important;
        }
        .ScrollbarsCustom-Track,
        .ScrollbarsCustom-TrackY {
            background-color: rgba(255, 255, 255, 0.315) !important;
            height: calc(100% - 165px) !important;
            top: 50px !important;
            right: -118px !important;
            z-index: -5;
            .ScrollbarsCustom-Thumb .ScrollbarsCustom-ThumbY {
                height: 85px !important;
            }
        }
    }
}
