@import '../../shared/sass/variables.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.quiz-container {
    background-color: white;
    border-radius: 25px;
    height: $work-section-height;
    margin-left: 24px;
    overflow: hidden;
    @media (max-width: $mobile) {
        height: 37rem;
        width: 93%;
    }
    >.title-area {
        @include center;
        background-color: $green-petroleum;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        width: 100%;
        height: 100px;
        font-size: 32px;
        @media (max-width: $mobile) {
            height: 55px;
            font-size: 16px;
        }
    }
    >.quiz-content-section {
        width: 100%;
        height: 80%;
        // @media (max-width: $mobile) {
        //     height: 50%;
        // }
    }
}