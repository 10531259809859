@import "../../shared/sass/variables.scss";

.video-container {
  width: 97%;
  background-color: $gray-darkness;
  margin: 16px 10px;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $mobile) {
    height: unset !important;
  }

  .video-description {
    width: 80%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding: 7px;

    @media (max-width: $mobile) {
      width: 100%;
      height: 80%;
    }

    .video-image-container {
      width: 25%;
      overflow: hidden;
      border-radius: 9px;
      background: black;

      @media (max-width: $mobile) {
        width: 100%;
      }

      .video {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        height: 100%;
        padding: 10px;
        position: relative;

        @media (max-width: $mobile) {
          width: 100%;
          height: 150px;
        }

        .blocked {
          height: 36px;
          width: 100%;
          background: $red;
          text-align: center;
          position: absolute;
          bottom: 0;
          padding: 8px;
          color: white;
        }

        .progress {
          width: 80%;
          margin: auto;
          position: relative;
          margin: auto;
          margin-bottom: 8px;

          .progress-bar-back {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: rgb(100, 100, 100);
            top: 90%;
          }

          .progress-bar-front {
            position: absolute;
            height: 2px;
            background-color: rgb(143, 10, 10);
            top: 90%;
          }
        }
      }
    }

    .description {
      width: 75%;
      color: white;
      padding: 16px 16px;

      @media (max-width: $mobile) {
        width: 100%;
        height: 50%;
      }

      .description-title {
        font-size: 20px;
        line-height: 21px;
        letter-spacing: 4px;
        font-weight: bolder;
      }

      .description-text {
        font-size: 12px;
        color: rgb(200, 200, 200);
        line-height: 16px;
        text-align: justify;
        h1 {
          color: white !important;
          font-size: 16px !important;
          font-weight: 900 !important;
          margin: 8px 0;
        }
        @media (max-width: $mobile) {
          h1 {
            text-align: unset !important;
          }
        }
      }
    }
  }

  .video-rating {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px;
    position: relative;
    width: 20%;

    @media (max-width: $mobile) {
      width: 100%;
      height: 20%;
      bottom: 20px;
    }

    .button-container {
      width: 75%;
      margin: auto;

      button {
        font-size: 10px;
        width: 100%;
        background: $red;
        border-radius: 3px;
        border: none;
        color: white;
        padding: 4px 16px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }

    .video-stars {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: auto;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }
}
