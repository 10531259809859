@import '../../shared/sass/variables.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-content: center;
}

h1 {
    font-weight: bold;
}

#custom-grid {
    @media (max-width: $mobile) {
        margin: auto;
    }
}

// .mobile {
//     @media (max-width: $mobile) {
//         display: flex;
//     }
// }

// .desktop {
//     @media (min-width: $mobile) {
//         display: flex;
//     }
// }

.public-profile {    
    @media (max-width: $mobile) {
        width: 100%;
        margin: auto;
    }
    .ScrollbarsCustom-Content {
        @media (max-width: $mobile) {
            padding-bottom: 180px !important;
        }
    }
    .ScrollbarsCustom-Track, .ScrollbarsCustom-Thumb {
        background-color: transparent !important;
    }
    .is-public-profile-mobile {
        @media (max-width: $mobile) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    .photo-section {
        @media (max-width: $mobile) {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-right: 0 !important;
        }
        .photo-circle {
            @include center;
            background-position: center center;
            border-radius: 50%;
            cursor: pointer;
            height: 250px;
            width: 250px; 
            overflow: hidden; 
            
            .img-photo {
                height: auto;
                max-width: 100%;
                max-height: 100%;
                @media (max-width: $mobile) {
                    border-radius: 50%;
                    height: 200px;
                    width: 200px;
                }
            }
            
            @media (max-width: $mobile) {
                height: 200px;
                width: 200px;
            } 
        }

        .photo-circle figure {
            display: flex;
        }

        .links-area {
            display: flex;
            justify-content: center;
            width: 250px;
            .change-photo, .delete-photo {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .networks-section {        
        margin-top: 75px;
        @media (max-width: $mobile) {
            display: flex;
            flex-direction: column;
            margin-top: 0 !important;
            margin-bottom: 150px;
            width: 100%;
        }

        .form-input-container {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .networks-section div {
        align-items: center;
        font-size: 25px;
        justify-content: center;
        @media (max-width: $mobile) {
            justify-content: flex-start;
        }
        .networks-input {
            margin-left: 10px;
        }
    }
    .is-settings-section {
        .description {
            .description-input {
                .textarea {
                    background-color: $gray-lighter;
                    border-radius: 20px;
                    height: 200px;

                    @media (max-width: $mobile) {
                        border-radius: 10px;
                    }
                }
                .textarea:focus {
                    border-color: #f0f0f0;
                }
            }
        }
    }
}