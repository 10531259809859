@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.ReactModalPortal {
    .ReactModal__Overlay--after-open {
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            background-color: $gray-lighter !important;
            border-radius: 20px !important;
            margin-bottom: 20% !important;
            margin-top: 7% !important;
            margin-left: 27% !important;
            margin-right: 27% !important;

            @media (min-width: $big-screen) {
                background-color: $gray-lighter !important;
                border-radius: 20px !important;
                margin-bottom: 20% !important;
                margin-top: 7% !important;
                margin-left: 32% !important;
                margin-right: 32% !important;
            }

            @media (max-width: $breakpoint) {
                background-color: $gray-lighter !important;
                border-radius: 20px !important;
                margin-bottom: 20% !important;
                margin-top: 7% !important;
                margin-left: 25% !important;
                margin-right: 25% !important;
            }

            @media (max-width: $mobile) {
                border-radius: 10px !important;
                margin-top: 40% !important;
                margin-left: 10% !important;
                margin-right: 10% !important;
            }
        }
    }
}

.purchase-modal {
    .power-purchase-container {
        border-radius: 20px;
        display: flex;
        top: 0;
        flex-direction: column;
        justify-content: center;
        > .exit-button-section {
            cursor: pointer;
            display: flex;
            font-size: 25px;
            justify-content: flex-end;
            margin: 10px;
        }
        .modal-content {
            display: flex;
            flex-direction: column;
            padding: 1.5rem 1rem;
            width: 100%;

            .bemaster-loader-container{
                margin: 15px auto;
                max-width: 70px;
            }

            > .img-space-section {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 20px;
                min-height: 220px;
                width: 100%;

                @include media-hack(xlg) {
                    min-height: 300px !important;
                }

                @media (max-width: 1200) {
                    min-height: 300px !important;
                }
            }
            > .video-space-section {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 20px;
                width: 100%;

                video {
                    border-radius: 20px !important;
                    box-shadow: 0px 0px 20px 0px black;
                }
            }
            .power-purchase-resume {
                margin-bottom: 20px;
                > .purchase-details {
                    h2 {
                        font-size: 15px !important;
                        font-weight: bold;
                    }
                    .price {
                        margin-right: 49%;
                    }
                    .price-mini-region {
                        align-items: center;
                        color: $brown;
                        display: flex;
                        font-weight: bold;
                        justify-content: space-between;

                        .p-details {
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            svg {
                                margin-right: 8px;
                                width: 8rem !important;
                            }

                            @include media-hack(sm) {
                                svg {
                                    width: 2.5rem !important;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: $mobile) {
                border-radius: 15px !important;
                margin: 0 auto !important;
                padding: unset !important;
                width: 90%;

                > .video-space-section {
                    video {
                        border-radius: 17px !important;
                        box-shadow: unset !important;
                    }
                }
                .power-purchase-resume {
                    .power-title {
                        h1 {
                            font-weight: 600;
                            font-size: 28px !important;
                            line-height: 30px;
                            text-align: center;
                            width: 100% !important;
                        }
                    }
                    .purchase-details {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h2 {
                            font-size: 12px !important;
                        }
                        .price-mini-region {
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            .p-details {
                                justify-content: center !important;
                                margin: 0.5rem 0;
                                width: 100%;

                                h1 {
                                    margin: 0 5px !important;
                                }
                            }
                            .is-custom-button {
                                display: flex;
                                justify-content: center;
                                margin-bottom: 15px !important;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
