@import '../../shared/sass/variables.scss';

.fire-records {
    .has-specific-details {
        background-color: $gray-lighter;
        border-radius: 25px;
        height: $fire-records-height;
        justify-content: space-between;
        width: $fire-records-width;
        @media (max-width: $mobile) {
            align-items: flex-start;
            border-radius: 10px !important;
            display: flex !important;
            flex-direction: column;
            height: 85px;
            justify-content: center;
            width: 100% !important;
            h1 {
                font-size: 10px !important;
            }
        }
        .level-left {
            @media (max-width: $mobile) {
                display: flex;
                margin: 0;
                .details {
                    line-height: 11px;
                    width: 75% !important;
                }
            }
        }
        .level-right {
            @media (max-width: $mobile) {                
                display: flex;
                justify-content: flex-end !important;
                margin: 0;
                width: 100%;
                h1 {
                    margin-top: 15px;
                }
            }
        }
        .level-left .movements-red {
            color: $red;
        }

        .level-left .movements-green {
            color: $green-toggle;
        }
    }
}