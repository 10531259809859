@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.main {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 33px;
    width: 33px;
}

.unchecked {
    @include theme-aware('border-color', 'unchecked-border-color');
    background-color: transparent;
    border: solid 1px $brown;
}

.checked {
    @include theme-aware('background-color', 'color-checked-mission');
    @include theme-aware('border-color', 'color-mission-item');
    background-color: $green-petroleum;
    border-color: $green-petroleum;
}

.checked-elite {
    background-color: $gray-dark-blue;
    border-color: $gray-dark-blue;
}

.unchecked-elite {
    background-color: transparent;
    border: solid 1px $gray-dark-blue;
}