@import '../../../shared/sass/variables.scss';

.main-dropdown-container {
    min-width: 215px;
    @media (max-width: $mobile) {
        min-width: 120px !important;
        width: 100%;
    }
    .css-2b097c-container {
        .css-yk16xz-control {
            border-radius: 25px;
        }
    }
}