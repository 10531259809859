@import "../../shared/sass/essentials.styles.scss";

.main-tool-list-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    flex-direction: column;
    padding: 35px 35px 5rem 35px;
    margin: 0;
    width: 100%;

    @include media-hack(sm) {
        align-items: center;
        padding: 15px !important;
    }

    @include media-hack(md) {
        margin: auto;
    }

    > h1 {
        color: black;
        font-weight: 800;
        font-size: 30px !important;
        margin: 0 0 30px 0;
        text-align: center;
    }

    .grid {
        display: grid;
        gap: 2rem;
        grid-auto-rows: 22rem;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

        @include media-hack(sm) {
            width: 85%;
        }
    }
}
