@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.tools-container {
    height: 100%;
    width: 92%;

    .tools-header {
        align-items: center;
        background: $gray-darkness;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        color: white;
        display: flex;
        font-size: 30px;
        height: 80px;
        padding: 0 30px;

        @include media-hack(sm) {
            justify-content: center;
        }

        h1 {
            font-weight: 900;
            font-size: 1.2rem !important;
            margin: 0 5px;
        }
    }

    .tools-list {
        align-items: center;
        background-color: #f5f5f5;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 35px 35px 15rem 35px;
        -webkit-flex-wrap: wrap;
        width: 100%;

        @media (max-width: $mobile) {
            display: block;
        }
        .tool-card {
            align-items: flex-end;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 15px;
            border: 1px solid $gray-tool;
            display: flex;
            height: 150px;
            margin: 10px 0;
            transition: all ease-in-out 0.36s;
            width: 45%;

            &:hover {
                cursor: pointer;
                transition: all ease-in-out 0.36s;

                -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
                -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.61);
            }

            @media (max-width: $mobile) {
                margin: 0 15px 20px auto;
                width: 90%;
            }
            .bottom-area :is(h1) {
                color: black;
                font-weight: 900;
                font-size: 0.8rem !important;
            }
            .bottom-area {
                align-items: center;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                border-radius: none;
                background: $gray-tool;
                display: flex;
                justify-content: space-between;
                height: 70px;
                padding: 5px 1.3rem;
                margin: 0 !important;
                width: 100%;
                > .is-custom-button {
                    margin: 0 !important;
                    button {
                        margin: 0 !important;
                        border-radius: 3px !important;
                        padding: 10px 5px !important;
                    }
                }

                h1 {
                    font-size: 1.05rem !important;
                }
            }
        }
    }
}
