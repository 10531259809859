@import "../../sass/variables.scss";

.sidebar-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 64px;
    height: 100%;
    background: $black;
    transition: all ease-in-out .5s;
    z-index: 100;

    .sidebar-content {
        position: fixed;
        width: 64px;
        height: 100%;
        top: 16px;
        transition: width 0.5s;
        overflow: hidden;

        .is-header-section {
            width: 300px;
            height: 32px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 64px;
            cursor: pointer;

            .is-sidebar-brand-icon {
                background-attachment: inherit;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: width ease-in-out 0.5s;
                height: 57px;
                width: 170px;
                margin-left: 24px;
                margin-top: 4px;
            }

            .brown-icon {
                background-image: url("https://cdn.bemaster.com/media/images/login/Logo-BeMaster.png");
            }
            
            .white-icon {
                background-image: url("https://cdn.bemaster.com/media/images/login/Logo-BeMaster-Elite.png");
            }

            .is-sidebar-arrow-icon {
                background-image: url("https://cdn.bemaster.com/media/icons/home/Sitiobemaster34.svg");
                background-size: cover;
                height: 32px;
                transform: rotate(-180deg);
                transition: 0.5s;
                width: 22px;
                margin-left: 20px;
                margin-top: 16px;
            }

            .is-sidebar-white-arrow-icon {
                background-image: url('https://cdn.bemaster.com/media/icons/home/Sitiobemaster34-White.svg');
                background-size: cover;
                height: 32px;
                margin-left: 20px;
                margin-top: 16px;
                transform: rotate(-180deg);
                transition: 0.5s;
                width: 22px;
            }
        }

        .is-sidebar-item {
            align-items: flex-start;
            display: flex;
            cursor: pointer;
            height: 32px;
            margin: 8px 0 8px 16px;
            width: 200px;
            
            &:hover > #itemIcon {
                animation: swing ease-in-out 0.25s 0s normal;
                filter: sepia(100%) saturate(200%) brightness(100%);
                transition: filter 0.05s;
            }
            
            .is-sidebar-icon {
                width: 32px;
                height: 32px;
                background-repeat: no-repeat;
                transition: all ease-in-out .5s;
            }
            
            .is-sidebar-name {
                margin-left: 16px;
                color: gray;
                font-size: 14px;
                font-weight: 400;
                transition: all ease-in-out .5s;
                
                &:hover {
                    color: $brown;
                    transition: color 0.05s;
                }
            }

            &:hover > #itemName {
                transition: all ease-in-out 5s;
            }
        }
    }
}