@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.has-background-image {
  @include has-background-image("https://cdn.bemaster.com/media/images/home/MainBackground.png");
}

.has-login-background {
  @include has-background-image("https://cdn.bemaster.com/media/images/home/MainLoginBackground.jpeg");
}

.has-elite-background {
  @include has-background-image("https://cdn.bemaster.com/media/images/home/Fondo-Pantalla.png");
}

.has-background-image-aff {
  @include has-background-image("https://cdn.bemaster.com/media/images/home/Imagendefondonegro.jpg");
}

.has-powerclass-background {
  @include theme-aware("background-image", "background-bemaster-primer");
  @include has-background-image("https://cdn.bemaster.com/media/images/powerclasses/Fondo.png");
  background-position: center;

  @include media-hack(sm) {
    overflow: hidden;
  }
}

.has-mentortalks-background {
  @include has-background-image("https://cdn.bemaster.com/media/images/mentortalks/Fondo_2.png");
}

.has-mentortalks-videos-background {
  @include has-background-image("https://cdn.bemaster.com/media/images/mentortalks/Fondo-06.png");
}

.support-layer {
  @include theme-aware("background", "background-support-dark");
  background: #ffffff !important;
}

.tools-layer {
  background-position: center;
  background-size: cover;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.has-custom-background {
  @media (max-width: $mobile) {
    height: -webkit-fill-available;
  }
  > .hero {
    @media (max-width: $mobile) {
      height: -webkit-fill-available;
    }
  }
}

.is-home-container {
  @media (max-width: $mobile) {
    flex-grow: 1;
  }
  > .community-custom-class {
    @media (max-width: $mobile) {
      align-items: flex-start;
      height: 100% !important;
      justify-content: center;
      .community-container {
        margin: 0 !important;
        .columns {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
      .ScrollbarsCustom-Content {
        padding-left: 15px !important;
      }
    }
  }
  > .is-content-section {
    display: flex;
    height: 100vh;
    justify-content: center;
    @media (min-width: $mobile) {
      align-items: flex-start;
    }
  }
  > .is-pclass-content-section {
    @media (max-width: $mobile) {
      align-items: flex-start;
      padding: 0 !important;
    }
  }
  > .is-mentor-content-section {
    @media (max-width: $mobile) {
      align-items: flex-start;
      display: flex;
      padding: 0 !important;
    }
  }
  > .is-home-content-section {
    padding: 0 10px 0 8% !important;
    @media (max-width: $mobile) {
      padding: 0 3px 0 8px !important;
    }
  }
  > .is-profile-content-section {
    @media (max-width: $mobile) {
      height: auto !important;
      padding: 0 !important;
      width: 100%;
    }
  }
  > .is-support-content-section {
    // padding: 0 0 0 4% !important;
    @media (max-width: $mobile) {
      align-items: baseline !important;
      width: 96% !important;
    }
  }
  > .is-shopping-content-section {
    padding: 0 0 0 5%;
    @media (max-width: $mobile) {
      width: 96% !important;
    }
  }
  > .is-fire-store-content-section {
    @media (max-width: $mobile) {
      margin: 0px !important;
      overflow-y: scroll !important;
      padding: 0 !important;
      width: 96% !important;
    }
  }
  .is-class-content-section {
    padding: 0 0 0 65px;
    height: 100vh;
    width: -webkit-fill-available;
    @media (max-width: $mobile) {
      padding: 0;
    }
  }
  .is-affiliates-content-section {
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  .is-videolist-content-section {
    padding: 0 !important;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  .is-tools-content-section {
    padding: 1rem 0rem 0 7rem !important;
    width: calc(100% - 60px) !important;
    @media (max-width: $mobile) {
      padding: 0 !important;
      margin-top: 1rem !important;
      width: 100% !important;
    }
  }
}

.has-down-info {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
