@import "../../../shared/sass/variables.scss";

.mentortalk-card-container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 195px;
}
  
.mentortalk-card {
    width: 90%;
    margin: auto;
    height: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 25px;
    transition: .2s;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .payment-content {
        background: $brown;
        width: 100%;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 160px;
        flex-direction: column;
    
        .price {
          display: flex;
          align-items: center;
          margin-bottom: 32px;
    
          .price-text {
            margin: 5px 8px 5px 8px;
            color: white;
            font-size: 32px;
            font-weight: bolder;
          }
        }
    }
    button {
        font-size: 10px;
        width: 100px;
        background: #f03246;
        border: none;
        color: white;
        padding: 4px 16px;
        margin-bottom: 16px;
        cursor: pointer;
        position: absolute;
        left: 20%;
        top: 85%;
    }
}

.mentortalk-card:hover {
    transition: .2s;
    transform: scale(1.05);
    border: solid 2px rgb(220,150,50);
    box-shadow: 0 0 5px 5px black;
}