@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

@mixin center {
    align-items: center;
    display: flex;
    justify-items: center;
}

.is-triumph-list-section {
    @include theme-aware('display', 'triumph-list-visible');
    align-items: center;
    background-color: #161616;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: 335px;
    padding: 30px 0 0 5px;
    width: 300px;
}

.is-content-section {
    justify-content: center;
    gap: 30px;
    // overflow: hidden !important;

    @media (max-width:$mobile){
        justify-content: unset;
    }
}
