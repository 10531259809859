@import '../../../../shared/sass/essentials.styles.scss';
@import '../../../../shared/sass/variables.scss';

.m-item-container {
    max-height: 285px;
    margin-top: 10px;
    .m-item {
        align-items: center;
        @media (max-width: $mobile) {
            font-size: 15px !important;
        }
        .m-completed {
            @include theme-aware('color', 'color-mission-item');
            color: $green-petroleum !important;
        }
    }
}

.m-item:hover {
    @include boxShadow(0px, 3px, 10px, 0px, 0.15);
    background-color:#eaeaea;
    cursor: pointer;
}