@import '../../shared/sass/variables.scss';
@import '../../shared/sass/essentials.styles.scss';

.center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.is-news-section {
    >.news-header {
        @include theme-aware('background-color', 'news-title-color-background');
        background-color: $brown;
        border-radius: 3px;
        height: 40px;
    }
    >.ScrollbarsCustom, .trackYVisible {
        width: 100%;
        @media (max-width: $mobile) {
            width: auto !important;
        }
    }
    .news-cards-container {
        overflow-y: auto;
        max-height: 400px;
        >.news-card-section {
            .is-card-container {
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 15px;
                height: 170px;
                align-items: flex-end;
                display: flex;
                padding: 15px;
                @media (max-width: $mobile) {
                  width: 95%;
                }
              }
            
        }

    }
    .news-no-content{
        color: white;
        font-size: 17px;
        letter-spacing: 0.9px;
        margin-top: 20px;
        text-align: center;
    }
}
