@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

@mixin elementHover($scale: 1.16, $delay: 0.4s, $hasBorder: true) {
    @if $hasBorder {
        border: 2px solid $brown;
    } @else {
        transition: all ease-in-out 0.1s;
    }

    transform: scale($scale);
    transition-delay: $delay;
}
.card-main-container {
    display: flex;
    max-width: 100%;
    
    @media (max-width: $mobile) {
        flex-direction: column;
    }

    .card-container {
        align-items: center;
        background-size: cover;
        backface-visibility: hidden;
        background-position: center center;
        border-radius: 22px;
        color: white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        justify-content: center;
        -moz-osx-font-smoothing: grayscale;
        min-width: 220px;
        margin: auto 10px;
        padding: 25px;
        height: 80%;

        // TRANSITIONS
        transition: transform ease-in-out 0.32s;

        &:hover {
            border: 1px solid #c19656c0;
            transition: transform ease-in-out 0.32s;
            transform: scale(1.02);

            @media (min-width: 600px) {
                @include boxShadow(19px, 20px, 23px, 3px);
            }
        }

        @media (max-width: $mobile) {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover !important;
            border-radius: 12px;
            margin: 0 0 16px 0;
            min-height: 160px !important;
            width: 100% !important;
        }
        > .career-title {
            align-items: center;
            display: flex;
            justify-content: center;
            > .is-be-name {
                letter-spacing: 5px;
                font-weight: 300;
            }

            > .is-career-name {
                letter-spacing: 5px;
                font-weight: 500;
            }
        }
    }

    .has-gif-loader {
        display: grid;
        height: 70%;
        place-items: center;

        img {
            width: 15%;
        }
    }
}
