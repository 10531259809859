.facebook-button {
    background-color: #1876f2 !important;
    border-radius: 60px !important;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 4%) !important;
    cursor: pointer !important;
    color: white;
    display: grid !important;
    font-size: 2.3rem !important;
    width: 62px !important;
    height: 62px !important;
    bottom: 6rem !important;
    position: absolute !important;
    place-items: center !important;
    right: 1.4rem !important;
    transition: transform ease-in-out 0.3s !important;
    z-index: 999;

    &:hover {
        transform: scale(1.1);
    }
}
