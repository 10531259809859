@import '../../shared/sass/variables.scss';

$custom-breakpoint: 1000px;

.class-video-container {
    width: 80%;
    // height: 480px;
    .video-react .video-react-progress-holder .video-react-play-progress {
        background-color: $red !important;
    }
    .video-react .video-react-play-progress:before {
        color: $red !important;
    }
    .video-react .video-react-big-play-button {
        background-color: $red !important;
    }
    @media (max-width: $custom-breakpoint) {
        // height: 210px;
        width: 100%;
    }
}