@import "../../../shared/sass/variables.scss";

.click-away {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: black;
  z-index: 100;
  transition: all ease-in-out .2s;
}

.video-sidebar {
  background: $black;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  display: grid;
  padding: 1rem 0.7rem; 
  position: fixed;
  place-items: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease-in-out 0.5s;
  z-index: 200;

  > .icon {
    height: 20px;
    width: 15px;
  }

  @media(max-width: $mobile) {
    opacity: .5;
  }
}
.video-menu {
  align-items: center;
  background: #161616;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0 30px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all ease-in-out 0.5s;
  width: 330px;
  z-index: 200;

  > .video-title {
    color: $brown;
    text-align: center;
    line-height: 1.5rem;
    margin: 0.1rem 0;
    font-size: 1.2rem;
  }

  > .video-subtitle {
    color: #977647;
    font-size: 12px;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  > .video-description {
    color: white;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    margin: 8px 0;
  }

  > .next-video-container {
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 8px;
    color: #977647;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin: 1.3rem auto;
    padding: 0.5rem;
    transition: all ease-in-out 0.5s;
    text-align: center;
    width: 100%;

    // &::before {
    //   background-color: transparent;
    //   width: 0;
    // }

    // &:hover::after {
    //   content: '';
    //   background-color: gray;
    //   width: 100%;
    // }
  }

  > .video-button-container {
    width: fit-content;
    margin: 16px auto;
  }

  > .return-classroom {
    display: flex;
    flex-direction: row;
    margin: 16px auto;
    align-items: center;
    cursor: pointer;

    &:hover {
      > .return-title {
        color: $brown;
      }

      > .return-icon {
        filter: sepia(100%) saturate(200%) brightness(100%);
      }
    }

    > .return-title {
      font-size: 16px;
      color: white;
      transition: all ease-in-out 0.2s;
    }

    > .return-icon {
      width: 48px;
      height: 48px;
      background-size: contain;
      background-repeat: no-repeat;
      transition: all ease-in-out 0.2s;
    }
  }
}
