@charset "utf-8";

@mixin default-button {
    align-items: center;
    border-radius: 5px !important;
    background-color: #d0d0d0;
    border-color: #d0d0d0;
    color: black;
    font-size: 13px !important;
    height: 33px;
    justify-content: center;
}

.button-default button {
    @include default-button;
}

.button-icon {
    font-size: 23px;
    margin-right: 10px;
}

.button-default button:hover {
    background-color: #b4b4b4;
    border-color: #b4b4b4;
}