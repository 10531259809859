@import "../../../shared/sass/variables.scss";

.modal-container {
  .close-button {
    cursor: pointer;
    font-size: 30px;
    top: 0;
    position: absolute;
    right: 5px;
  }

  .title {
    font-size: 24px;
    color: $gray-darkness;
  }

  .mastertools-form {
    label {
      font-size: 18px;
      color: $gray-darkness;
    }

    input {
      width: 100%;
      padding: 6px;
      border: 1px solid $brown;
      border-radius: 4px;
      margin: 8px auto;
    }

    .button-container {
      margin: 0 auto;
    }
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.753) !important;
    z-index: 1010 !important;
    .ReactModal__Content--after-open {
      align-items: center;
      background: white;
      border: transparent !important;
      border-radius: 15px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 20% !important;
      bottom: 20% !important;
      left: 31% !important;
      right: 31% !important;
      overflow: hidden !important;
      @media (max-width: $mobile) {
        bottom: 20% !important;
        right: 10% !important;
        left: 10% !important;
        top: 20% !important;
        .congrats-text {
          font-size: 20px !important;
        }
        .content-text {
          font-size: 15px !important;
          text-align: center;
        }
      }
    }
  }
}
