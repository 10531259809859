@import '../../shared/sass/variables.scss';

.request-sent-modal {
    h1 {
        color: $gray-lighter !important;
        font-weight: normal !important;
        letter-spacing: 3px;
    }
    .close-button {
        color: $gray-lighter;
        cursor: pointer;
        font-size: 30px;
        top: 0;
        position: absolute;
        right: 5px;
    }
    .content-text {
        font-size: 15px !important;
        line-height: 20px;
        text-align: center;
    }
    .ReactModal__Overlay--after-open { 
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            align-items: center;
            background: rgb(47,95,85) !important;
            background: linear-gradient(90deg, rgba(47,95,85,1) 0%, rgba(47,95,85,1) 50%, rgba(77,153,137,1) 100%) !important;
            border: transparent !important;
            border-radius: 15px !important; 
            bottom: 37% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 37% !important;
            left: 31% !important;
            right: 31% !important;
            overflow: hidden !important;        
            .content-text {
                font-size: 17px !important;
                text-align: center;
            }
            @media (max-width: $mobile) {
                bottom: 42% !important;
                right: 10% !important;
                left: 10% !important;
                top: 37% !important;
                .sent-text {
                    font-size: 20px !important;
                }
                .content-text {
                    font-size: 17px !important;
                    text-align: center;
                }
            }
        }
    }
}