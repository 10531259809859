@import "../../../components/notification-container/notification-container.styles.scss";
@import "../../../shared/sass/essentials.styles.scss";
@import "../../../shared/sass/variables.scss";

@mixin center {
    display: flex;
    justify-content: center;
}

.settings-container {
    @include navbar-basic-container(-10px, 155px, 95px);
    padding: 2px 15px;
    @media (max-width: $mobile) {
        margin-right: 10px;
    }
    .links-section {
        font-size: 13px !important;
        .account-link {
            @include theme-aware("display", "account-link-visible");
            border-bottom: solid 0.7px rgba(179, 179, 179, 0.685);
            display: unset;
            padding-bottom: 1px;
            span {
                font-size: 13px !important;
                cursor: pointer;
            }
        }
        .link {
            transition: 0.1s;
        }

        .link:hover {
            text-shadow: 0px 0px 2px $gray-lighter;
            transition: 0.1s;
        }

        .profile-link {
            cursor: pointer;
        }
        .help-link {
            cursor: pointer;
        }
        .logout-link {
            cursor: pointer;
        }
        .careers-link {
            cursor: pointer;
        }
    }
}

.heartbeat {
    -webkit-animation: heartbeat-animation 1.5s ease-in-out 3 both;
    animation: heartbeat-animation 1.5s ease-in-out 3 both;
}
