@import '../../shared/sass/variables.scss';

.reminder-modal {
    .close-button {
        cursor: pointer;
        font-size: 30px;
        top: 0;
        position: absolute;
        right: 5px;
    }
    .bottom-text {
        font-size: 12px !important;
        text-align: center;
    }
    .ReactModal__Overlay--after-open { 
        background-color: rgba(0, 0, 0, 0.753) !important;
        z-index: 1010 !important;
        .ReactModal__Content--after-open {
            align-items: center;
            background-color: $gray-lighter !important;
            border-color: $gray-lighter !important;
            border-radius: 20px !important;
            bottom: 34% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 34% !important;
            left: 31% !important;
            right: 31% !important;
            min-width: 380px;
            .bottom-text {
                h1 {
                    font-size: 20px !important;
                    letter-spacing: 3px;
                    line-height: 25px;
                }
            }
            .reminder {
                color: $red !important;
                font-weight: bold;
                letter-spacing: 4px;
            }
            @media (max-width: $mobile) {
                bottom: 66% !important;
                top: 20% !important;
                left: 3% !important;
                right: 52% !important;
                min-width: auto;
                .bottom-text {
                    h1 { 
                        font-size: 12px !important;
                        line-height: 15px;
                        margin-top: 15px;
                    }
                }
                .reminder {
                    color: $red !important;
                    font-size: 15px !important;
                    line-height: 10px;
                }
                .class-buttom {
                    margin-top: 10px !important;
                }
            }
        }
    }
}   