@import "../../shared/sass/variables.scss";

.thumbY {
    background: $brown !important;
}

.trackY {
    background: #00000057 !important;
}

.main-powerclass-row {
    .powerclass-title {
        font-size: 24px;
        color: white;
        text-align: center;
        letter-spacing: 4px;
        margin-bottom: 20px;
        @media (max-width: $mobile) {
            font-size: 20px;
            margin: 20px 0;
        }
    }
    
    .powerclass-masters-container {
        width: 80vw;
        margin: auto;
        height: 300px;
        display: flex;

        @media (max-width: $mobile) {
            height: 70% !important;
        }
    
        .powerclass-masters-list {
            display: flex;
            overflow-x: scroll;
            width: 90vw;
            margin: auto;
            height: 100%;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                display: none !important;
            }

            @media (max-width: $mobile) {
                flex-direction: column !important;
                overflow: auto;
                width: 100%;
            }
        }
    
        .scroll-arrow {
            transition: all 0.25s ease;
            background: transparent;
            border-radius: 7px;
            border: none;
            color: white;
            cursor: pointer;
            font-size: 55px;
            height: 100%;
            min-width: 80px;
            outline: none;
            z-index: 1000;
        }
        .scroll-arrow:hover {
            background-color: #0000005d;
        }
    }
}
