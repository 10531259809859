@import '../../shared/sass/variables.scss';

.main-shopping-container {
    background-color: $grey-dark;
    border-top-left-radius: 20px;  
    border-top-right-radius: 20px;
    height: 100%;
    min-width: 95%;
    @media (max-width: $mobile) {
        overflow-y: auto;
        min-width: 100%;
    }
    .mega-banner {
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #c0a477;
        background-position: center;
        border-top-left-radius: 20px;  
        border-top-right-radius: 20px;
        height: 310px;
        @media (max-width: $mobile) {
            height: 180px !important;
        }
        >.content-area {
            @media (max-width: $mobile) {
                .banner-title {
                    font-size: 30px !important;
                    line-height: 33px !important;
                }
                width: 220px !important;
            }
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 400px;
            h1 {
                line-height: 43px;
            }
        }
    }
    .bottom-shop-section {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 40px 20px;
        width: 100%;
        @media (max-width: $mobile) {
            padding: 10px !important;
        }
        .main-product-container {
            @media (max-width: $mobile) {
                grid-gap: 30px !important;
                margin-bottom: 360px;
            }
        }
    }
}

// CDN Simulation
.product0 {
    background-image: url('https://cdn.bemaster.com/media/images/shopping/products/product0.PNG');
}