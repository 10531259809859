@import "../../../shared/sass/variables.scss";

.main-content-description-container {
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5018382352941176) 0%,
        rgba(0, 0, 0, 0.7035189075630253) 79%,
        rgba(0, 0, 0, 1) 100%
    );
    bottom: 0;
    display: grid;
    height: 100%;
    opacity: 0;
    place-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .inner-description-container {
        display: flex;
        flex-direction: column;
        height: 40%;
        margin-top: 2rem;
        width: 60%;
        
        .chapter-title {
            font-size: 3rem !important;
            font-weight: bold !important;
        }
        
        .p-font {
            color: $gray-pause !important;
        }
        
        .main-description {
            font-size: 15px !important;
            line-height: 1.2rem;
            width: 70%;
        }
    }

    .title-font {
        color: white !important;
    }

    .state-description-container {
        display: flex;
        justify-content: flex-end;
        width: 60%;
    }
}

.sleep-container {
    transition: opacity ease-in-out 0.9s;
    opacity: 1 !important;
    z-index: 9;
}
