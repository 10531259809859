@import "../../shared/sass/variables.scss";
@import "../card/card.styles.scss";

.main-certification-video-container {
    background-color: $grey-dark;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 100%;
    min-width: 82%;
    @media (max-width: $mobile) {
        overflow-y: auto;
        width: 90% !important;
    }

    .bemaster-loader {
        display: grid;
        height: 100%;
        place-items: center;
        width: 100%;
        
        img {
            height: 64px;
            width: 64px;
        }
    }

    .mega-title {
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background: rgb(193, 150, 86);
        background: linear-gradient(
            90deg,
            rgba(193, 150, 86, 1) 0%,
            rgba(175, 140, 88, 1) 76%,
            rgba(224, 199, 161, 1) 100%
        );
        background-position: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        height: 15%;
        justify-content: center;
        h1 {
            color: white;
            line-height: 30px;
            letter-spacing: 6px;
            text-align: center;
            width: 65%;
        }
        @media (max-width: $mobile) {
            height: 180px !important;
        }
    }
    .bottom-video-certification-section {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start !important;
        overflow-y: scroll !important;
        padding-top: 25px;
        width: 100%;

        .react-player__preview {
            @include boxShadow(0, 0, 30px, 0px, 2);
        }
        .video-container {
            width: 50%;
        }
        .celebration-text {
            color: $support-input !important;
            letter-spacing: 3px;
            font-size: 12px !important;
            line-height: 20px;
            text-align: center;
            width: 90%;
        }
        .last-text {
            color: $support-input !important;
            letter-spacing: 3px;
            font-size: 12px !important;
        }
        .bottom-resume {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 15rem !important;
            text-align: center;
            width: 100%;

            > .button {
                margin-top: 5px !important;
                margin-bottom: 10rem !important;
                width: 50% !important;
            }
        }
        @media (max-width: $mobile) {
            padding: 20px !important;
        }
    }
}
