@import "../../shared/sass/variables.scss";

.main-card-container {
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    height: 200px;
    justify-content: center;
    padding: 10% 3%;
    width: 150px;
    
    &:hover {
        border: 1px solid $brown;
        transition: border ease-in-out 0.35s;
    }

    h1 {
        font-weight: 600 !important;
        text-align: center;
    }

    @media (max-width: $mobile) {
        width: 100% !important;
        h1 {
            font-size: 16px !important;
        }
    }
}
