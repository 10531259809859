@import "../../shared/sass/variables.scss";

.thumbY {
    background: $brown !important;
}

.from-powerclass {
    background: $black;
}

.video-content {
    width: 80vw;
    max-width: $big-screen;
    height: 100vh;
    margin: 32px auto;
    border-radius: 32px;
    overflow: hidden;

    @media (max-width: $mobile) {
        width: 90vw;
    }

    > .has-loader {
        display: grid;
        height: 70%;
        place-items: center;

        img {
            width: 60px;
        }
    }

    .banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        height: 200px;
        font-size: 30px;
        padding: 18px;
        position: relative;
        width: 100%;

        @media (max-width: $mobile) {
            height: 180px;
        }

        .darkened-layout {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(0, 0, 0, 0.7371323529411764) 5%,
                rgba(0, 0, 0, 0) 100%
            );
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .back-button {
            position: absolute;
            padding: 0.8rem;
            left: 0;
            top: 0;
            z-index: 100;

            .hide{
                display: none;
            }
        }

        svg {
            cursor: pointer;
        }

        button {
            padding: 8px 48px;
            font-weight: bolder;
            color: white;
            cursor: pointer;
            border-radius: 8px;
            border: none;
            left: 2.5rem;
            bottom: 10%;
            position: absolute;

            @media (max-width: $mobile) {
                top: unset;
                left: 1rem;
                bottom: 16px;
                margin: 0 auto;
            }
        }

        .master-name {
            width: 450px;
            position: absolute;
            left: 48px;
            background: #c19656;
            padding: 16px 32px 8px 32px;
            border-radius: 15px;
            text-align: center;
            color: white;
            font-size: 24px;
            letter-spacing: 4px;
        }

        > .heart {
            cursor: pointer;
            filter: drop-shadow(0 2px 8px black);
            font-size: 30px;
            position: absolute;
            right: 3%;
            top: 10%;

            @media (max-width: $mobile) {
                font-size: 20px;
                margin: 0px 5px;
            }
        }

        .hearts-quantity {
            position: absolute;
            right: 3%;
            top: 28%;
            font-size: 16px;
            color: white;
            background: rgba(50, 50, 50, 0.3);
            border-radius: 5px;
            font-weight: bolder;
        }

        .stars-rating {
            position: absolute;
            right: 0;
            top: 60%;
            margin-right: 10px;

            @media (max-width: $mobile) {
                font-size: 20px !important;
                top: 57%;
                width: 40%;
            }

            .rating-stars {
                filter: drop-shadow(1px 3px 4px black);
                height: 40px;
                margin-left: 15px;

                @media (max-width: $mobile) {
                    font-size: 20px !important;
                    margin-left: 0 !important;
                    width: 150px;
                }
            }

            .rating-text {
                align-items: center;
                color: $brown;
                display: flex;
                font-size: 15px;
                justify-content: flex-end;
                text-align: center;

                h2 {
                    margin: 0 0.5rem;
                }

                @media (max-width: $mobile) {
                    svg {
                        width: 20px !important;
                    }
                    h2 {
                        font-size: 16px !important;
                        font-weight: 700;
                        width: 100px;
                    }
                }
            }
        }
    }

    .video-list-container {
        width: 95%;
        height: 90%;
        margin: auto;
        overflow-y: scroll;
        padding-bottom: 400px;

        @media (max-width: $mobile) {
            &::-webkit-scrollbar {
                display: none !important;
            }
        }
    }

    .banner-classroom {
        background-image: url("https://cdn.bemaster.com/media/images/powerclasses/Banner.png");
        background-size: contain;
        background-repeat: no-repeat;
        height: 200px;
        width: 100%;

        @media (max-width: $mobile) {
            height: 64px;
        }
    }
}

::-webkit-scrollbar {
    display: none !important;
}
