@import '../../shared/sass/variables.scss';

.level-finished-message-container {
    align-items: center;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    padding: 0 0 4rem 0;

    @media (max-width: $mobile) {
        margin: auto;
        padding: 25px 10px;
        width: 90% !important;
    }

    .bemaster-loader {
        display: grid;
        height: 100%;
        padding-top: 4rem;
        place-items: center;
        width: 100%;
        
        img {
            height: 64px;
            width: 64px;
        }
    }

    .title-area {
        margin-top: 20px;
        @media (max-width: $mobile) {
            text-align: center;
            margin-top: 0;
            h1 {
                font-size: 14px !important;
                line-height: 17px;
            }
        }
        h1 {
            color: $brown !important;
            font-weight: normal !important;
            letter-spacing: 5px;
        }
    }

    .certification-area {
        align-items: center;
        color: $dark-gray;
        display: flex;
        flex-direction: column;
        .mainMessage {
            font-weight: bold !important;
            text-align: center;
            line-height: 20px;
            margin-top: 12px;
            margin-bottom: 10px;
            width: 85%;
            @media (max-width: $mobile) {
                font-size: 13px !important;
                line-height: 17px;
                width: 100%;
            }
        }
        .secondMessage {
            color: $brown;
            line-height: 20px;
            text-align: center;
            margin-bottom: 15px;
            width: 60%;
            @media (max-width: $mobile) {
                width: 100% !important;
            }
        }

        img {
            border-radius: 1rem;
            width: 60%;
        }
    }
    .button-area {
        margin-top: 15px;
        button {
            margin: 0 !important; 
        }
        h1 {
            letter-spacing: 2px !important;
        }
    }
}