@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.is-nav-section {
  align-items: center;
  color: white !important;
  display: flex;
  font-size: 14px;
  letter-spacing: 1px;
  min-height: 90px;
  padding: 0px 0px 0 65px !important;
  width: -webkit-fill-available;

  @media (min-width: 576px) {
    justify-content: flex-end;
    margin-right: 32px;
  }

  @media (max-width: $mobile) {
    @include theme-aware("justify-content", "home-navbar-items-order");
    // justify-content: flex-end !important;
    padding: 0 0 0 0 !important;
  }

  @media (max-width: 320px) {
    align-items: center;
    justify-content: flex-end !important;
  }

  @media (max-width: 375px) {
    justify-content: flex-end !important;
    padding: 0 !important;
  }

  .is-arrow-icon {
    @media (min-width: $mobile) {
      display: none !important;
    }
  }

  .navbar-content {
    .dark {
      color: $dark-gray;
    }
  }

  > .is-brand-section {
    @include theme-aware("margin-top", "logo-home-margin-top");
    @include theme-aware("margin-bottom", "logo-home-margin-bottom");
    display: flex;
    margin-bottom: unset;
    margin-top: unset;

    @media (max-width: 320px) {
      display: none;
    }
  }

  > .main-icon {
    img {
      height: 12px;
    }
  }

  > .is-shopping-brand-section {
    width: 120px !important;
  }

  .navbar-end {
    align-items: center;

    > .notification-parent {
      margin-top: 10px;
    }

    > .notification-parent,
    .settings-parent {
      height: 35px;
    }

    > .notification-parent {
      margin-top: 16px;
      width: 24px;
    }

    > .common-link {
      align-items: center;
      color: $brown;
      display: flex;
      font-weight: 600;
    }

    > .my-balance {
      align-items: center;
      border: 1px solid $brown;
      border-radius: 2rem;
      color: $brown;
      display: flex;
      padding: 5px 1.5rem;

      h1 {
        font-size: 12px !important;
      }

      @media (max-width: $mobile) {
        border: none;

        h1 {
          font-size: 10px !important;
        }
        .balance-text {
          display: none;
        }
      }

      > .balance-pack {
        align-items: center;
        display: flex;
        grid-gap: 10px;

        @media (max-width: $mobile) {
          align-items: center;
          flex-direction: column;
          gap: 0 !important;
          justify-content: center;
        }
      }
    }
  }
}

.custom-calculator-navbar {
  background-color: black !important;
}

.tools-theme {
  background-color: $gray-darkness !important;
}
