@import "../../shared/sass/essentials.styles.scss";
@import "../../shared/sass/variables.scss";

.main-float-sidebar-container {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100vh;
  position: absolute;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 70px;
  z-index: 9;

  @include media-hack(sm) {
    display: none;
  }

  > .trigger-sidebar-container {
    background-color: $brown;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    cursor: pointer;
    display: grid;
    height: 60px;
    place-items: center;
    position: fixed;
    transition: all ease-in-out 0.32s;
    width: 40px;
    z-index: 9;

    svg {
      cursor: pointer;
    }
  }

  > .float-sidebar-container {
    align-items: center;
    background-color: $brown;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    position: fixed;
    transition: margin-left ease-in-out 0.35s;
    width: 60px;
    padding: 2rem 0;

    > .float-sidebar-icon {
      &:hover {
        animation: swing ease-in-out 0.25s 0s normal;
        filter: sepia(100%) saturate(200%) brightness(100%);
        transition: filter 0.05s;
      }

      cursor: pointer;
      height: 30px;
      padding: 0 3px;
      width:30px;      

      fill: white !important;

      > svg {
        filter: invert(1);
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
