@import "../../shared/sass/variables.scss";

@mixin center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.is-progress-by-career-card {
  margin-bottom: 8%;
  width: 90%;
  @media (max-width: $mobile) {
    width: 100% !important;
  }
  .bottom-progress-container {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    height: 250px;
    margin-top: 3%;
    padding: 40px 35px;
    @media (max-width: $mobile) {
      height: 100% !important;
      margin-top: 5% !important;
      padding: 35px 35px;
      width: 100% !important;
    }
    .text-lef-side {
      h1 {
        font-weight: 100;
      }
      display: flex;
      flex-direction: column;
      .main-title {
        // align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        letter-spacing: 8px;
        line-height: 28px;
        margin-bottom: 50px;
        width: 100%;
        color: white;
        @media (max-width: $mobile) {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 50px;
          line-height: 28px;
          letter-spacing: 2px;
          h1 {
            font-weight: 600 !important;
          }
          .is-size-4 {
            color: $brown;
            letter-spacing: 5px;
          }
        }
        .subtitle {
          color: $brown;
        }
        h1 {
          font-weight: 600;
        }
      }
      .custom-grey {
        color: $form-border;
      }
    }

    .is-total-level-progress {
      align-items: center;
      border-radius: 15px;
      background-color: $grey-dark !important;
      display: flex;
      height: 30px;
      margin-top: 12%;
      position: relative;

      @media (max-width: $mobile) {
        margin: 0 !important;
      }

      .junior-level {
        align-items: center;
        height: 100%;
        position: relative;
        display: flex;
        width: 100%;
        h1 {
          position: absolute;
          z-index: 5;
        }
        .internal-junior-level {
          @include center;
          border-radius: 15px;
          background-color: $red;
          height: 100%;
        }
      }
      .senior-level {
        align-items: center;
        display: flex;
        height: 100%;
        position: relative;
        width: 100%;
        h1 {
          position: absolute;
        }
        .internal-senior-level {
          @include center;
          border-radius: 15px;
          background-color: $green-petroleum;
          height: 100%;
        }
      }
      .master-level {
        align-items: center;
        display: flex;
        height: 100%;
        position: relative;
        width: 100%;
        h1 {
          position: absolute;
        }
        .internal-master-level {
          @include center;
          background-color: $brown;
          border-radius: 15px;
          height: 100%;
        }
      }

      .here-you-are {
        color: $brown;
        display: flex;
        font-size: 30px;
        font-weight: bold;
        flex-direction: column;
        margin-bottom: 70px;
        line-height: 15px;
        position: absolute;
        h1 {
          display: flex;
          margin: -22px 0 0 -45px;
          position: absolute;
          width: 130px;
        }
        svg {
          position: absolute;
          top: -10px;
        }
      }
    }
  }
}
