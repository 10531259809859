@import "../../shared/sass/variables.scss";

.main-input-container {
    align-items: center;
    background-color: #5e5e5e4a;
    border-radius: 40px;
    border: solid 1px $form-border;
    color: white;
    font-size: 25px;
    height: 34px !important;
    width: 780px !important;
    padding: 0 20px;
    z-index: 3;

    @media (max-width: $mobile) {
        width: 85% !important;
    }

    .search-icon {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 25px;
    }
    .input-area {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 32px;
    }

    .dark-click-away {
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    input,
    select,
    .search-area {
        background: transparent !important;
        border: none;
        color: white;
        font-size: 14px;
        height: 95%;
        outline: none;
        width: 100%;
    }
    input::placeholder {
        font-style: italic;
    }
    .results-container {
        background-color: #161616;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 0.1rem;
        max-height: 370px;
        overflow: hidden;
        position: relative;
        padding: 20px 17px 17px 25px;
        z-index: 2;

        @media (max-width: $mobile) {
            padding: 10px 15px !important;
        }

        > .results-title {
            border-bottom: 1px solid $dark-gray;
            color: $form-border !important;
            width: 93%;

            p {
                font-style: italic !important;
                font-size: 13px;

                @media (max-width: $mobile) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        > .results-visualize-container {
            overflow: scroll;

            > .data-filtered {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                overflow-y: scroll;
                width: 104%;

                @media (max-width: $mobile) {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr;
                    overflow-y: scroll;
                }
                .pclass-card-container:hover {
                    transform: none !important;
                }

                .col-generated {
                    @media (max-width: $mobile) {
                        width: 97% !important;
                    }
                }
            }
        }
    }
}
.main-mentortalks-container {
    .mentor-searchbox {
        @media (max-width: $mobile) {
            width: 100% !important;
        }
    }
}
