@import "../../shared/sass/variables.scss";

.main-powerclass-container {
    height: 100%;
    padding-bottom: 5%;
    width: 100vw;

    .ScrollbarsCustom {
        .ScrollbarsCustom-Wrapper {
            inset: 0 -10px 250px 0 !important;
        }
        .ScrollbarsCustom-Track,
        .ScrollbarsCustom-TrackY {
            height: calc(100% - 220px) !important;
            .ScrollbarsCustom-Thumb,
            .ScrollbarsCustom-ThumbY {
                height: 200px !important;
            }
        }
        .ScrollbarsCustom-Scroller {
            padding-top: 10px !important;
        }
    }

    .search-section {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 80px;
        width: 100%;
        @media (max-width: $mobile) {
            height: 40px;
            .ScrollbarsCustom-Content {
                padding-bottom: 120px !important;
            }
        }
    }
    .powerclass-content-section {
        height: 100vh;
        width: 100%;
        @media (max-width: $mobile) {
            margin-left: 5px !important;
        }
        .powerclass-content {
            padding-bottom: 80px !important;

            .scroll-slide {
                display: flex;
                height: 137px;
                width: 88vw;
                .power {
                    display: flex;
                    height: 152px;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    scroll-behavior: smooth;
                    z-index: 1;
                    @media (max-width: $mobile) {
                        align-items: center;
                        display: flex;
                        height: auto;
                        margin-bottom: 12px;
                        overflow-x: auto;
                        overflow-y: hidden;
                    }
                    .powerclass-cat-card {
                        align-items: center;
                        display: flex;
                        justify-content: flex-start;
                        width: 250px;
                    }
                    .powerclass-main-row {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 20px;
                        @media (max-width: $mobile) {
                            margin-left: 5px;
                        }
                    }
                }
            }
            .scroll-slide:hover {
                .scroll-arrow {
                    opacity: 1;
                }
            }
        }
    }

    .powerclass-banner {
        width: 30%;
        margin: auto;
        @media (max-width: $mobile) {
            margin: 20px auto !important;
            width: 80%;
        }
    }
    
    .powerclass-title {
        font-size: 24px;
        color: white;
        text-align: center;
        letter-spacing: 4px;
        margin-bottom: 5px;
        @media (max-width: $mobile) {
            font-size: 20px;
            margin: 20px 0;
        }
    }

    .powerclass-masters-container {
        width: 80vw;
        margin: auto;
        height: 300px;
        display: flex;

        @media (max-width: $mobile) {
            height: 70% !important;
        }

        .powerclass-masters-list {
            display: flex;
            overflow-x: scroll;
            width: 90vw;
            margin: auto;
            height: 100%;
            scroll-behavior: smooth;
            &::-webkit-scrollbar {
                display: none !important;
            }
            @media (max-width: $mobile) {
                flex-direction: column !important;
                overflow: auto;
                width: 100%;
            }
        }

        .scroll-arrow {
            transition: all 0.25s ease;
            background: transparent;
            border-radius: 7px;
            border: none;
            color: white;
            cursor: pointer;
            font-size: 55px;
            height: 100%;
            min-width: 80px;
            outline: none;
            z-index: 1000;
        }
        .scroll-arrow:hover {
            background-color: #0000005d;
        }
    }
}
