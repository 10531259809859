@import '../../../../shared/sass/variables.scss';
@import '../../../../shared/sass/essentials.styles.scss';

.has-background-image {
  background-image: url('https://cdn.bemaster.com/media/images/login/Imagendefondo.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.is-front-layer {
  position: relative;
  // min-width: max-content;
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: row;
  }

  >.is-brand-section {
    width: 100%;
  }
  
  .loader-animation {
    background-color: transparent !important;

    .ReactModalPortal {
      .ReactModalPortal .ReactModal__Overlay--after-open .ReactModal__Content--after-open {
        background-color: transparent !important;
      }
      .ReactModal__Overlay--after-open { 
          background-color: transparent !important;
          z-index: 1010 !important;
          position: absolute;
          overflow: hidden !important;        
          outline: none !important;
          .ReactModal__Content--after-open {
              align-items: center;
              background-color: transparent !important;
              border: none !important;
              bottom: 0% !important;
              display: flex;
              flex-direction: column;
              justify-content: center;
              top: 5% !important;
              left: 35% !important;
              right: 35% !important;
              overflow: hidden !important;
              outline: none !important;
          }
      }
    }
  }
}

.login-component {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.is-back-layer {
  position: fixed;
}