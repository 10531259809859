@import '../../sass/variables.scss';

.class-button {
    border: none !important;
    margin-top: 30px;
}

.red {
    background-color: $red !important;
}

.green-petroleum {
    background-color: $green-petroleum !important;
}

.blue {
    background-color: $info !important;
}

.brown {
    background-color: $brown !important;
}