@import "../../shared/sass/variables.scss";

.warning-modal {

  .close-button {
    color: black;
    cursor: pointer;
    font-size: 30px;
    top: 0;
    position: absolute;
    right: 5px;
  }

  .fullname {
    font-weight: bolder;
  }

  p {
    color: $black !important;
    font-weight: normal !important;
    text-align: center;
  }

  .warning-title {
    font-size: 16px;
    font-weight: bolder;
    margin: 8px auto;
  }

  .calls-to-action {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 0.5em;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.753) !important;
    z-index: 1010 !important;
    .ReactModal__Content--after-open {
      align-items: center;
      background: rgb(0, 0, 0) !important;
      background: linear-gradient(
        90deg,
        rgb(255, 255, 255) 0%,
        rgb(255, 255, 255) 50%,
        rgb(255, 255, 255) 100%
      ) !important;
      border: transparent !important;
      border-radius: 15px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 30% !important;
      bottom: 30% !important;
      left: 29% !important;
      right: 29% !important;
      overflow: hidden !important;
      @media (max-width: $mobile) {
        bottom: 20% !important;
        right: 10% !important;
        left: 10% !important;
        top: 20% !important;
        .congrats-text {
          font-size: 20px !important;
        }
        .content-text {
          font-size: 15px !important;
          text-align: center;
        }
      }
    }
  }
}
