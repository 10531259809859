.input-password-container {
  border-radius: 10px;
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;

  > input {
    background: transparent;
    outline: none;
    font-size: 1rem;
    font-family: "Gotham", sans-serif;
    color: #363636;
    border: none;
    width: 100%;
  }

  > .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.dark {
  background: white;
}

.light {
  background: rgb(240,240,240);
  border-radius: 20px;
}