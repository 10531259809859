@import "../../shared/sass/variables.scss";

.card-list {
    display: flex;
    height: 60vh;
    justify-content: center;
    overflow-x: auto;
    width: 80vw;

    @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        height: unset;
        grid-gap: 0px;
        padding: 0 1rem !important;
        overflow-x: visible;
        width: 90vw;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    @media (max-width: 1140px) {
        justify-content: flex-start;
    }

    &::-webkit-scrollbar-thumb {
        background: $brown !important;
        border-radius: 30px;
        cursor: pointer !important;
        scroll-behavior: smooth;
        max-width: 200px !important;
    }
    
    &::-webkit-scrollbar {
        background: rgba(0, 0, 0, 0.342) !important;
        border-radius: 30px;
        cursor: pointer !important;
        display: block !important;
        scroll-behavior: smooth;
        height: 10px !important;
    }
}

.carousel-card-list {
    @media (max-width: $mobile) {
        padding: 0 !important;
    }
}
