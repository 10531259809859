@import '../../sass/variables.scss';

.is-form-input {
    background-color: $gray-lighter;
    border-radius: 20px !important;
    outline: none;
    @media (max-width: $mobile) {    
        width: 100% !important;
    }
}

.is-form-input:focus, select:focus, 
select:active, input:focus, input:active,
input:hover {
    outline: none;
    border: none;
}

.alert-area, .alert-icon {
    color: $red;
    display: flex;
}