@import '../../shared/sass/essentials.styles.scss';
@import '../../shared/sass/variables.scss';

.awards-container {
    // padding-bottom: 120px;
    padding-bottom: 80px;
    margin-bottom: 100px;
    @media (max-width: $mobile) {
        display: grid;
        grid-template-columns: 100%;
        margin: 0 !important;
        padding-bottom: 100px;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        color: $grey-dark;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    .award-card {
        align-items: center;
        background-color: $grey-lighter;
        border-radius: 20px;
        display: flex;
        transition: all ease-in-out 0.3s;
        @media (max-width: $mobile) {
            flex-direction: column;
            align-items: center;
            gap: 15px;
            text-align: center;
            border-radius: 15px;
        }
        .icon-award {
            align-items: center;
            max-height: 90px;
            display: flex;
        }

        .award-content {
            line-height: 1.5;
        }
    }
}

.award-card:hover {
    @include boxShadow(1px, 1.5px, 8px, 1px, 0.25);
    transform: scale(1.03);
} 