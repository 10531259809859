@charset "utf-8";
@import "./animations.styles.scss";

// Custom project variables
@import "./variables.scss";

// Mixins
@mixin center() {
    align-items: center;
    display: center;
    justify-content: center;
}

@mixin boxShadow($pX, $pY, $spread, $shadowSize, $darkScale: 0.72) {
    $sColor: rgba(0, 0, 0, $darkScale);
    box-shadow: $pX $pY $spread $shadowSize $sColor;
    -webkit-box-shadow: $pX $pY $spread $shadowSize $sColor;
    -moz-box-shadow: $pX $pY $spread $shadowSize $sColor;
}

//Mixin to get into the themes map
@mixin theme-aware($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color);
        }
    }
}

// Basic Modal In Navbar Components
@mixin navbar-basic-container($mr: -10px, $width: 265px, $minHeight: 250px) {
    align-items: center;
    border-radius: 12px;
    background-color: #2b2b2b;
    flex-direction: column;
    margin-top: 35px;
    margin-right: $mr;
    min-height: $minHeight;
    max-height: 230px;
    transition: all linear 0.25s;
    position: absolute;
    width: $width;
    z-index: 101;
}

// layout - background image
@mixin has-background-image($url-image) {
    // @include theme-aware('background-image', 'background-home');
    background-image: url($url-image);
    background-position: center center fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

// Custom background layout
@mixin has-custom-background($color: $gray-lighter) {
    background-color: $color;
}

// Responsive map
$breakpoints: (
    sm: 540px,
    md: 768px,
    lg: 1024px,
    xlg: 1200px,
);

// Media query Hack
@mixin media-hack($breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
    }
}

// IMPORTANT: ALL VARIABLES HAS TO BE CUSTOMIZED BEFORE IMPORTING BULMA

// Bulma Import
@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/bulma/sass/components/dropdown.sass";

// Pro-Side-Bar Imports
//@import '../../../node_modules/react-pro-sidebar/dist/scss/styles.scss';

//Personalized Icons
@import "./icons.styles.scss";
