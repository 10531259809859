@import "../../../shared/sass/variables.scss";

.tour-layout {
    background: rgba(18, 15, 18, 0.9);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.tour-card {
    background: $black;
    border-radius: 15px;
    position: fixed;
    z-index: 106;
    overflow: hidden;
    margin: auto;
    
    .exit-tour {
        position: absolute;
        right: 4px;
        top: 4px;
        background: rgb(10,10,10);
        border-radius: 50%;
        padding: 2px 10px;
        color: white;
        cursor: pointer;
    }
    
    img {
        width: 100%;
    }

    video {
        height: 100%;
    }

    .tour-content {
        
        .card-title {
            color: white;
            text-align: center;
            margin-top: 8px;
        }
        
        .card-content {
            color: white;
            font-size: 12px;
            justify-content: center;
            margin-bottom: 32px;
        }
    }
    

    .card-backward {
        font-size: 12px;
        color: $brown;
        position: absolute;
        left: 16px;
        bottom: 16px;
        cursor: pointer;
    }

    .card-forward {
        font-size: 12px;
        color: $brown;
        position: absolute;
        right: 16px;
        bottom: 16px;
        cursor: pointer;
    }

    .card-index {
        position: absolute;
        left: 45%;
        bottom: 16px;
        text-align: center;
        color: $brown;
    }
}