@import '../../shared/sass/variables.scss';

.back-button {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    height: 50px;
    font-size: 3rem;
    justify-content: center;
    left: 4% !important;
    position: absolute;
    top: 3% !important;
    transition: all ease-in-out 0.7s;
    width: 50px;
    z-index: 3000;
}