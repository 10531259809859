@import "../../shared/sass/variables.scss";

.main-rating-container {
  align-items: center;
  justify-content: center;
  z-index: 1020;

  > .rating-clickaway {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }

  .is-rating-section {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.747);
    border-radius: 12px;
    // bottom: 20%;
    display: flex;
    font-weight: 100 !important;
    flex-direction: column;
    // height: 170px;
    justify-content: center;
    line-height: 55px;
    letter-spacing: 4px;
    padding: 16px 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    @media (max-width: $mobile) {
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 43%;
      h1 {
        font-size: 20px !important;
        text-align: center;
        line-height: 30px;
      }
    }

    > .next-video {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .favorite-mini-section {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: 5px auto;
        font-size: 25px;

        > .favorite-icon {
          transition: all ease-in-out 0.2s;

          &:hover {
            transform: scale(1.2);
          }
        }

        h1 {
          font-size: 15px;
          letter-spacing: 3px;
          line-height: 16px;
          margin: 0 1rem; 
          transition: all ease-in-out 0.2s;
          color: white;

          &:hover {
            color: $brown;
          }
        }
      }
      > .autoplay-animation {
        height: 50px;
        width: 250px;
      }
      > .next-subtitle {
        color: rgb(230, 230, 230);
        font-size: 12px;
      }

      > .next-video-title {
        font-size: 20px;
        line-height: 22px;
        color: white;
        font-weight: bolder;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        text-align: center;

        &:hover {
          color: $brown;
        }
      }

      > .next-video-icon {
        cursor: pointer;
        transition: all ease-in-out 0.2s;

        &:hover {
          color: $brown;
          transform: scale(1.1);
        }
      }

      > .return-classroom {
        width: 64px;
        height: 64px;
        margin: 16px 0;
        transition: all ease-in-out 0.2s;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
          color: $brown;
          filter: sepia(100%) saturate(200%) brightness(100%);
        }
      }
    }
  }
}
