@import "../../shared/sass/variables.scss";
@import "../../shared/sass/essentials.styles.scss";

.class-info {
    .rating-title-section {
        align-items: center;
        .class-rating-prom {
            @include theme-aware("color", "color-rating");
            color: $brown !important;
        }
    }

    .descriptions-section {
        @media (max-width: $breakpoint) {
            margin-bottom: 0;
        }
    }
}

.video {
    display: flex;
    justify-content: center;
}
